<template>
    <div class="fullpost-wrapper mt-105">
        <div class="new-preview pos-rel f-col">
            <div class="image-new pos-rel" v-bind:style="{
                backgroundImage: 'url(' + news.full_image + ')',
            }"></div>
            <div class="mw-1560 title-wrapper">
                <h4 class="title">{{ news.title }}</h4>
                <p>{{ humanDate }}</p>
            </div>
        </div>
        <div class="mw-1560 new-content">
            <h6>А подробнее?</h6>
            {{ news.full_text }}
        </div>
    </div>
</template>

<style lang="scss">
@import "@/assets/scss/components/fullpost.scss";
</style>

<script>
import axios from "axios";
import { DateTime } from "luxon";
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
            news: "",
            styleObject: {
                color: "red",
                fontSize: "13px",
            },
        };
    },

    setup() {
        return { toast: useToast() };
    },

    methods: {
        WhenPublished(time) {
            const myDateTime = DateTime.fromSeconds(time);
            const myDateTimeISO = myDateTime.toISO();
            return DateTime.fromISO(myDateTimeISO).toLocaleString(
                "dd LLL yyyy, HH:MM"
            );
        },
    },

    computed: {
        humanDate: function () {
            return DateTime.fromISO(this.news.creation_date).toFormat(
                "dd MM yyyy, HH:MM"
            );
        },
    },

    mounted() {
        this.$nextTick(function () {
            axios
                .get(
                    `https://api.minexia.ru/v2/news/full/${this.$route.params.id}`
                )
                .then((response) => {
                    this.news = response.data.news;
                    document.title = this.news.title;
                })
                .catch((e) => {
                    if (e.response.status === 500) {
                        this.toast.error(
                            "Что-то пошло не так, попробуйте позднее! (>ᆺ>)",
                            {
                                position: "bottom-right",
                            }
                        );
                    }
                });
        });
    },
};
</script>

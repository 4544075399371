<template>
    <svg
        width="146"
        height="113"
        viewBox="0 0 146 113"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M143 20.0667V92.6C143 102.026 135.402 109.667 126.03 109.667H19.9697C10.5976 109.667 3 102.026 3 92.6V20.0667M143 20.0667C143 10.641 135.402 3 126.03 3H19.9697C10.5976 3 3 10.641 3 20.0667M143 20.0667L73 60.6L3 20.0667"
            stroke="#FF4646"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

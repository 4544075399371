<template>
    <div class="modalBackground">
        <div class="modalWrapper authModal">
            <div class="f-row sp-b ali-c z10">
                <p class="modalTitle">авторизация</p>
                <span @click="closeModal" class="z10">
                    <svg class="z10" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                            fill="white" />
                    </svg>
                </span>
            </div>

            <div class="f-col auth-form z10">
                <input v-model="form.login" type="text" class="st-input" name="username" pattern="[0-9A-Za-z_]{3,16}"
                    maxlength="16" placeholder="Ваш никнейм" />
                <input v-model="form.password" type="password" class="st-input" name="password" maxlength="32"
                    placeholder="Пароль" />
            </div>
            <button @click="authMeSenpai" class="stButton w100 z10">
                Авторизоваться
            </button>
            <div class="f-row sp-b">
                <router-link to="/auth/register" class="stText authModalText" @click="closeModal()">
                    Зарегистрироваться
                </router-link>
                <router-link to="/auth/lostpassword" class="stText authModalText" @click="closeModal()">
                    Восстановить пароль
                </router-link>
            </div>
            <div class="f-row socAuthModal">
                <svg class="z10" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.24702e-05 4.60635L0 15.4289L2.34091e-05 16.7705L3.04756e-05 27.5931C3.34146e-05 30.1384 2.06178 32.1994 4.60709 32.1994H14.7278H16.1304H27.653C30.1983 32.1994 32.2609 30.1384 32.2609 27.5931V16.7705V15.4289V4.49607C32.2609 1.95076 30.1983 -2.86245e-06 27.653 0L16.1304 7.33219e-06H14.7278H4.60707C2.06176 7.33219e-06 1.76567e-05 2.06104 1.24702e-05 4.60635ZM5.64574 10.3081H8.06586C8.68438 10.3081 8.91707 10.5788 9.149 11.2488C10.3327 14.6959 12.3284 17.7085 13.1488 17.7085C13.4565 17.7085 13.5976 17.5668 13.5976 16.7856V13.2225C13.5421 12.2083 13.1877 11.7697 12.9252 11.4448C12.763 11.2442 12.636 11.087 12.636 10.8643C12.636 10.5936 12.8673 10.3081 13.2518 10.3081H17.0549C17.5678 10.3081 17.7463 10.5823 17.7463 11.1976V15.991C17.7463 16.5036 17.969 16.683 18.1229 16.683C18.4305 16.683 18.687 16.5036 19.2511 15.9397C20.6285 14.4006 21.6858 12.2055 22.069 11.3515C22.1763 11.1125 22.2572 10.8562 22.4281 10.6575C22.6033 10.4537 22.8665 10.3081 23.2781 10.3081H25.6982C26.4301 10.3081 26.5839 10.6848 26.4301 11.1976C26.2171 12.1733 24.7393 14.4627 23.8493 15.7801C23.4049 16.438 22.6864 17.21 23.1738 17.8366V17.8366C23.3003 18.0135 23.57 18.2776 23.8786 18.5799C24.1959 18.8907 24.5544 19.2418 24.8404 19.5797C25.88 20.7489 26.6635 21.7353 26.8817 22.4151C27.0812 23.0972 26.7354 23.4429 26.0439 23.4429H23.6238C22.9756 23.4429 22.6493 23.0799 21.9439 22.2955C21.6449 21.963 21.2779 21.5548 20.7894 21.0664C19.3536 19.6822 18.7382 19.5027 18.3792 19.5027C17.8922 19.5027 17.7464 19.6407 17.7464 20.332V22.5019C17.7464 23.0972 17.5561 23.4429 16.0177 23.4429C13.4538 23.4429 10.6361 21.8866 8.63626 19.0158C5.63645 14.8118 4.81598 11.6333 4.81598 10.9924C4.81598 10.6336 4.95427 10.3081 5.64574 10.3081Z"
                        fill="white" fill-opacity="0.92" />
                </svg>
                <svg class="z10" width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.5366 15.4688C12.4858 15.4688 11.6562 16.3905 11.6562 17.515C11.6562 18.6395 12.5043 19.5613 13.5366 19.5613C14.5874 19.5613 15.4169 18.6395 15.4169 17.515C15.4354 16.3905 14.5874 15.4688 13.5366 15.4688ZM20.2653 15.4688C19.2145 15.4688 18.3849 16.3905 18.3849 17.515C18.3849 18.6395 19.2329 19.5613 20.2653 19.5613C21.3161 19.5613 22.1456 18.6395 22.1456 17.515C22.1456 16.3905 21.3161 15.4688 20.2653 15.4688Z"
                        fill="white" fill-opacity="0.92" />
                    <path
                        d="M29.2239 0H4.52132C2.43819 0 0.742188 1.696 0.742188 3.79756V28.7214C0.742188 30.823 2.43819 32.519 4.52132 32.519H25.4264L24.4493 29.1085L26.809 31.3023L29.0396 33.367L33.0031 36.8696V3.79756C33.0031 1.696 31.3071 0 29.2239 0ZM22.1081 24.0758C22.1081 24.0758 21.4444 23.2831 20.8914 22.5826C23.3064 21.9005 24.2281 20.3889 24.2281 20.3889C23.4723 20.8866 22.7533 21.2369 22.1081 21.4765C21.1864 21.8637 20.3015 22.1217 19.4351 22.2692C17.6653 22.601 16.0431 22.5089 14.6604 22.2508C13.6097 22.048 12.7064 21.753 11.9505 21.4581C11.5265 21.2922 11.0657 21.0894 10.6048 20.8313C10.5495 20.7944 10.4942 20.776 10.4389 20.7391C10.402 20.7207 10.3836 20.7023 10.3651 20.6838C10.0333 20.4995 9.84897 20.3704 9.84897 20.3704C9.84897 20.3704 10.7338 21.8452 13.0751 22.5457C12.522 23.2463 11.8399 24.0758 11.8399 24.0758C7.76584 23.9468 6.21732 21.2737 6.21732 21.2737C6.21732 15.3377 8.87193 10.5263 8.87193 10.5263C11.5265 8.5353 14.0521 8.59061 14.0521 8.59061L14.2364 8.81183C10.9182 9.77043 9.3881 11.2268 9.3881 11.2268C9.3881 11.2268 9.79367 11.0056 10.4758 10.6922C12.4483 9.82574 14.0152 9.58609 14.6604 9.53078C14.7711 9.51235 14.8632 9.49391 14.9738 9.49391C16.0984 9.34643 17.3704 9.30957 18.6977 9.45704C20.449 9.65983 22.3293 10.176 24.2465 11.2268C24.2465 11.2268 22.7902 9.84417 19.6563 8.88557L19.9144 8.59061C19.9144 8.59061 22.4399 8.5353 25.0945 10.5263C25.0945 10.5263 27.7491 15.3377 27.7491 21.2737C27.7491 21.2737 26.1822 23.9468 22.1081 24.0758Z"
                        fill="white" fill-opacity="0.92" />
                </svg>
            </div>
            <img src="@/assets/images/decor/liteEllipse.svg" class="pos-abs liteEl notSel" />
            <img src="@/assets/images/decor/hardEllipse.svg" class="pos-abs hardEl notSel" />
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
            form: {
                login: "",
                password: "",
            },
        };
    },
    setup() {
        return { toast: useToast() };
    },
    methods: {
        async authMeSenpai() {
            try {
                const result = (
                    await axios.post(
                        "https://api.minexia.ru/v2/login",
                        this.form
                    )
                ).data;
                this.toast.success("Привет, ты вернулся! (^ᆺ^)", {
                    position: "bottom-right",
                });
                this.$store.commit("userSetInfo", result.user);
                this.$store.commit("userSetToken", result.token);
                this.$store.commit("closeModal");
            } catch (e) {
                if (e.response.status === 500) {
                    this.toast.error(
                        "Что-то пошло не так, попробуйте позднее! (>ᆺ>)",
                        {
                            position: "bottom-right",
                        }
                    );
                }
            }
        },
        closeModal() {
            this.$store.commit("closeModal");
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/classes.scss";
@import "@/assets/scss/variables.scss";

.auth-form {
    margin-top: 75px;

    .st-input:first-child {
        margin-bottom: 20px;
    }
}

.authModal {
    .stButton {
        margin-top: 35px;
    }
}

path,
svg {
    transition: 0.4s;
}

svg:hover>path {
    fill: #ff4646;
}

svg:hover {
    transform: scale(1.07);
    cursor: pointer;
}
</style>

<template>
    <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 15L17.6603 5.75123e-07L0.339746 1.33223e-06L9 15ZM7.5 6.55669e-08L7.5 1.5L10.5 1.5L10.5 -6.55669e-08L7.5 6.55669e-08Z"
            fill="white"
        />
    </svg>
</template>

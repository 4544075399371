<template>
    <div class="mw-1560 mb-f-f">
        <div class="first-block"></div>
        <div class="title-page">
            <h2>Правила проекта</h2>
            <p>
                Перед тем как начать играть, ознакомьтесь с правилами, чтобы не
                разрушать комфортную атмосферу для всех игроков! (˃ᆺ˂)
            </p>
        </div>
        <div class="rules-content">
            <div class="rul-chapter" :class="{ fullRules: ch1 }" @click="this.ch1 = !this.ch1">
                <h4>1. Основные правила проекта</h4>
                <ol>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.1</b> Каждый пользователь,
                        прошедший регистрацию, автоматически соглашается с
                        правилами проекта и политикой конфиденциальности
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.2.</b> Администрация проекта
                        имеет полное право заблокировать ваш аккаунт за
                        нарушение того или иного свода правил.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.3.</b> Денежные средства
                        потранные на Донат услуги считаются добровольными
                        пожертвованиями на развитие проекта. Возврат денежных
                        средств возможен только в случае ошибки при совершении
                        платежа онлайн кассы или со стороны проекта.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.4.</b> Все жалобы, вопросы и
                        предложения рассматриваются только на форуме.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.5.</b> Администрация не
                        несет ответственность за ваши потерянные вещи в игре.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.6.</b> Перенос купленных
                        предметов в онлайн магазине осуществляется только после
                        рассмотрения заявки на форуме.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.7.</b> Незнание правил не
                        освобождает вас от ответственности. Все наказания
                        выдаются строго в соответствии с правилами проекта.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.8.</b> Независимо от статуса
                        игрока, будь то игрок, модератор или администратор - все
                        равны и все понесут наказание за нарушение правил.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">1.9.</b> Окончательное слово
                        всегда лежит за Администрацией проекта.
                    </li>
                </ol>
            </div>
            <div class="rul-chapter" :class="{ fullRules: ch2 }" @click="this.ch2 = !this.ch2">
                <h4>2. Правила общения в чате</h4>
                <ol>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.1</b> Запрещено употребление
                        мата
                        <br />
                        <p class="remark">Примечание:</p>
                        Сюда относится завуалированный мат, мат на иностранных
                        языках, транслит. <br />
                        <p class="punishment">
                            <b>Наказание:</b> Мут от 30 минут до 6 часов
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.2</b> Запрещено
                        оскорбительное поведение по отношению к другим игрокам
                        <br />
                        <p class="remark">Примечание:</p>
                        Сюда относится оскорбление личности, непристойное
                        поведение, троллинг, флейм, буллинг <br />
                        <p class="punishment">
                            <b>Наказание:</b> Мут от 1 часа
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.3</b> Запрещено оскорбление
                        родных и близких
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Мут от 4 часов или бан от 24ч
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.4</b> Запрещено отправление
                        в чат провокационных сообщений, разжигание конфликтов,
                        расизм, нацизм, пропаганда наркотических и алкогольных
                        веществ, суицида, обсуждение террористический
                        организаций, запрещенны на территории РФ, затрагивание
                        политических тем и им подобных
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 3 дней
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.5</b> Запрещен флуд
                        <br />
                        <p class="remark">Примечание:</p>
                        Запрещено повторение одного и того же сообщение более
                        3-х раз короткий срок времени, запрещена бессмысленная
                        организация флуда, например "Кто согласен что я топ 1
                        ставим +"
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Мут от 30 минут до 6 часов
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.6</b> Запрещено пускать
                        любые ложные сведения о сервере где вы
                        находитесь/проекте и его администрации.
                        <br />
                        <p class="remark">Примечание:</p>
                        Не нужно придумывать правила, они уже прописаны, не
                        разглашайте конфиденциальную информацию о которой узнали
                        случайно, не разглашайте ложную информацию, связанную с
                        серверами
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Мут от 30 минут до 6 часов
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.7</b> Запрещено оскорбление,
                        унижение, ущемление прав администрации, принуждение
                        администрации к исполнению несвойственных ему
                        обязанностей.
                        <br />
                        <p class="remark">Примечание:</p>
                        Запрещена провокация администрации на нарушение правил
                        проекта
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан до 14 дней
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.8</b> Запрещена реклама.
                        <br />
                        <p class="remark">Примечание:</p>
                        Запрещена реклама других проектов, сторонних файлов,
                        программ, которые можно отнести к читам, майнерам,
                        соц.сетей и каналов которые не имеют отношения к проекту
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 12ч до 90 дней
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.9</b> Запрещено вводить в
                        заблуждение игроков/администрацию по техническим и
                        игровым аспектам.
                        <br />
                        <p class="remark">Примечание:</p>
                        Сюда относится "Умею дюпать", "Скину читы", "Этот
                        предмет работает не так, он только для донатов"
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 12ч до 90 дней
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.10</b> Запрещено
                        попрошайничество.
                        <br />
                        <p class="remark">Примечание:</p>
                        Запрещено выпрашивать ресурсы в глобальном чате у
                        игроков, администрации проекта, в том числе просить
                        сменить день/ночь.
                        <br />
                        <p class="punishment"><b>Наказание:</b> Мут от 2ч</p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">2.11</b> Запрещено выдавать
                        себя за администрацию/модерацию проекта.
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 2 дней
                        </p>
                    </li>
                </ol>
            </div>
            <div class="rul-chapter" :class="{ fullRules: ch3 }" @click="this.ch3 = !this.ch3">
                <h4>3. Игровой процесс</h4>
                <ol>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.1</b> Запрещено проводить
                        раздачи игровых ресурсов в любом виде. Также запрещено
                        упоминать и информировать о раздачах.
                        <br />
                        <p class="remark">Примечание:</p>
                        Игрок, который подобрал вещи на раздаче, обязан
                        уничтожить их, либо предоставить администрации, иначе
                        игрок тоже понесет наказание <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 3 дней до 180 дней,
                            уничтожение полученных вещей или снос
                            постройки/склада вещей
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.2</b> Запрещена всевозможная
                        дестабилизация работы сервера, создание лагов/лаг зон,
                        умышленное падение сервера, использование и
                        распространение игровых неисправностей, багов, дюпов
                        <br />
                        <p class="remark">Примечание:</p>
                        Помощь дюперу в использовании или тестировании
                        запрещена. Соучастники получат такое же наказание, что и
                        исполнитель. <br />
                        <br />
                        Человек, нашедший любой изъян в работе сервера и
                        сообщивший администрации, будет вознаграждён
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 15 дней
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.3</b> Запрещено
                        использование стороннего ПО, читов
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 30 дней + снос построек
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.4</b> Запрещено гриферство
                        <br />
                        <p class="remark">Примечание:</p>
                        - Если ограбил 1 из участников привата или постройка вне
                        привата - игрок не попадает под нарушение этого правила.
                        (Если у потерпевшего есть док-ва того, что он был
                        обманут, то это расценивается как гриферство). <br />
                        - Под гриферство попадают любые действия, которые вредят
                        игрокам (пример: окапывание регионов, заливание регионов
                        любой жидкостью, вытаскивание любых ресурсов из
                        региона). <br />
                        - Любое застраивание региона, игроком не состоящим в
                        регионе запрещено. <br />
                        - Запрещено ставить любые телепорты около чужих регионов
                        с целью убийства игрока. <br />
                        - Убивать мирных животных в загонах/выталкивать животных
                        из загонов. <br />
                        - Уничтожение чужих посевов.
                        <br />
                        <p class="punishment">
                            <b>Наказание: бан от 3 дней до 90 дней</b>
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.5</b>Запрещена продажа или
                        покупка вещей за реальные деньги. Так же запрещена
                        продажа или передача игрового аккаунта.
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Полная блокировка игрового
                            аккаунта
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.6</b> Запрещено проводить
                        неравноценный обмен
                        <br />
                        <p class="remark">Примечание:</p>
                        - Администрация имеет право самостоятельно решить,
                        является ли обмен равноценным или нет. <br />
                        - Равноценность при обмене определяется исходя из
                        ценности крафта.

                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> бан от 24ч до 7 дней
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.7</b> Запрещено
                        строительство/выкапывание свастики, половых органов и
                        т.п.
                        <br />
                        <p class="remark">Примечание:</p>
                        Постройка домов в подобной тематике, также запрещена.
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> бан от 24ч до 30 дней + снос
                            постройки
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.8</b> Запрещено ставить
                        точку спавна своего дома/варпа (/sethome, /home set,
                        /warp *) в следующих случаях.
                        <br />
                        <p class="remark">Примечание:</p>
                        - Если в данной области находится чужой приват (не
                        относится к данному правилу ситуация, когда владелец
                        региона сам разрешил поставить, warp/home на своей
                        территории). <br />
                        - Если в области включен PVP режим. <br />
                        - Если под этой точной находится ловушка, способная
                        убить человека. <br />
                        - Данное правило относится по большей части к WARPам, к
                        точке HOME оно применяется только, если вы приглашаете
                        туда не знакомых лично вам игроков
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> Бан от 3 дней
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">3.9</b> Запрещен обман при
                        обмене
                        <br />
                        <p class="remark">Примечание:</p>
                        - Запрещено воровать, мешать, убивать во время обмена.
                        <br />
                        - В случае потери предмета администратор имеет право
                        вернуть вещи потерпевшему
                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> бан от 24ч до 7 дней
                        </p>
                    </li>
                </ol>
            </div>
            <div class="rul-chapter" :class="{ fullRules: ch4 }" @click="this.ch4 = !this.ch4">
                <h4>4. Создание аккаунтов</h4>
                <ol>
                    <li class="pos-rel">
                        <b class="number pos-abs">4.1</b> Запрещено
                        регистрировать никнеймы/префиксы содержащие сексуальный
                        или аморальный характер, оскорбление, нецензурную брать,
                        рекламу и т.п.
                        <p class="remark">Примечание:</p>
                        - Запрещено создание никнеймов схожих с никнеймами
                        членов администрации. <br />
                        - Запрещено создание никнеймов/префиксов содержащих
                        отсылку к модерации, администрации. <br />
                        - Запрещено создание технических никнеймов/префиксов
                        (Admin, Moder, Test, Bug, Dupe и т.п).

                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> удаление аккаунта, удаление
                            префикса
                        </p>
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">4.2</b> Запрещено
                        устанавливать скины, плащи, аватарки непристойного вида
                        <p class="remark">Примечание:</p>
                        -Запрещено устанавливать скины, плащи, аватарки
                        сексуального характера. <br />
                        - Запрещена пропаганда нацизма, расизма.

                        <br />
                        <p class="punishment">
                            <b>Наказание:</b> удаление скина, плаща + бан от 3
                            дней
                        </p>
                    </li>
                </ol>
            </div>
            <div class="rul-chapter" :class="{ fullRules: ch5 }" @click="this.ch5 = !this.ch5">
                <h4>5. Социальные сети проекта и другие медиа ресурсы</h4>
                <ol>
                    <li class="pos-rel">
                        <b class="number pos-abs">5.1</b> Запрещено неадекватное
                        поведение и чрезмерное употребление мата во всех чатах,
                        голосовых каналах, комментариях.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">5.2</b> Запрещено разжигание
                        конфликтов.
                        <p class="remark">Примечание:</p>
                        – Запрещено оскорбление родных и близких человека.
                        <br />
                        – Запрещено подстрекать, унижать, ущемлять права и
                        достоинства собеседника. <br />
                        – Запрещены оскорбительные высказывания в чей-либо
                        адрес.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">5.3</b> Запрещена любая
                        реклама не относящаяся к проекту
                        <p class="remark">Примечание:</p>
                        - Запрещена реклама других проектов <br />
                        - Запрещена реклама сторонних файлов/програм. <br />
                        - Запрещена реклама соц. сетей, каналов, которые не
                        имеют никакого отношения к проекту.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">5.4</b> Не рекомендуется
                        тревожить команду проекта по ненужным вопросам в
                        discord, VK
                    </li>
                </ol>
            </div>
            <div class="rul-chapter" :class="{ fullRules: ch6 }" @click="this.ch6 = !this.ch6">
                <h4>6. Права и обязанности администрации проекта</h4>
                <ol>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.1</b> Каждый
                        модератор/администратор действует исключительно в рамках
                        данного свода правил.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.2</b> Каждому
                        модератору/администратору запрещено раскрывать
                        внутренние новости проекта.
                        <p class="remark">Примечание:</p>
                        Внутренние новости: даты вайпов, даты обновлений и их
                        содержание, уход с поста других сотрудников
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.3</b>
                        Модератору/администратору запрещено подстрекать игроков
                        на нарушение правил
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.4</b>
                        Модератор/администратор обязан давать отчёт своим
                        действия.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.5</b>
                        Модератор/администратор обязан хранить в течение 7-ми
                        дней доказательство о нарушении правила игроком
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.6</b>
                        Модератор/администратор обязан представить
                        доказательство нарушения правила только в том случае,
                        если: поступила жалоба или вопрос на форум или в группу
                        Vk.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.7</b> Любой член команды
                        проекта может быть снят с занимаемой им должности, если
                        он будет вносить разногласия в коллектив, строить
                        заговоры, вести себя неадекватно и не соблюдать цензуру
                        общения по отношению к игрокам и коллегам.
                    </li>
                    <li class="pos-rel">
                        <b class="number pos-abs">6.8</b>
                        Модератору/администратору имеющему доступ в креативный
                        режим, запрещено выдавать какие либо ресурсы игрокам и
                        использовать свои возможности, если он развивается
                        совместно с другими игроками.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/rules.scss";
</style>

<script>
export default {
    data() {
        return {
            ch1: false,
            ch2: false,
            ch3: false,
            ch4: false,
            ch5: false,
            ch6: false,
        };
    },

    created() {
        document.title = "Minexia - правила проекта";
    },
};
</script>

<template>
    <div class="server-container stBack f-col" v-bind:style="{
        backgroundImage:
            'linear-gradient(0deg, rgba(40, 41, 44, 0.55), rgba(40, 41, 44, 0.55)),url(' + serverBackground +
            ')',
    }">

        <div class="info-container f-row">
            <div class="f-col">
                <h3 class="server-name">{{ serverName }}</h3>
                <p class="short-desc">{{ shortDesc }}</p>
            </div>
        </div>
        <div class="server-tags f-row">
            <div class="tag">{{ serverVersion }}</div>
            <div class="tag">{{ battleStatus }}</div>
            <div class="tag">Последний вайп: {{ lastVipe }}</div>
            <div class="online-container f-row">
                <p>{{ onlinePlayers }} игроков</p>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6"
                        d="M4.5 5C3.59375 5 2.78125 4.53125 2.3125 3.75C1.875 3 1.875 2.03125 2.3125 1.25C2.78125 0.5 3.59375 0 4.5 0C5.375 0 6.1875 0.5 6.65625 1.25C7.09375 2.03125 7.09375 3 6.65625 3.75C6.1875 4.53125 5.375 5 4.5 5ZM16 5C15.0938 5 14.2812 4.53125 13.8125 3.75C13.375 3 13.375 2.03125 13.8125 1.25C14.2812 0.5 15.0938 0 16 0C16.875 0 17.6875 0.5 18.1562 1.25C18.5938 2.03125 18.5938 3 18.1562 3.75C17.6875 4.53125 16.875 5 16 5ZM0 9.34375C0 7.5 1.46875 6 3.3125 6H4.65625C5.15625 6 5.625 6.125 6.0625 6.3125C6 6.53125 6 6.78125 6 7C6 8.21875 6.5 9.28125 7.34375 10H7.3125H0.65625C0.28125 10 0 9.71875 0 9.34375ZM12.6562 10H12.625C13.4688 9.28125 14 8.21875 14 7C14 6.78125 13.9688 6.53125 13.9375 6.3125C14.3438 6.125 14.8125 6 15.3125 6H16.6562C18.5 6 20 7.5 20 9.34375C20 9.71875 19.6875 10 19.3125 10H12.6562ZM10 5.5C9.4375 5.5 8.96875 5.8125 8.6875 6.25C8.40625 6.71875 8.40625 7.3125 8.6875 7.75C8.96875 8.21875 9.4375 8.5 10 8.5C10.5312 8.5 11 8.21875 11.2812 7.75C11.5625 7.3125 11.5625 6.71875 11.2812 6.25C11 5.8125 10.5312 5.5 10 5.5ZM10 10C8.90625 10 7.9375 9.4375 7.375 8.5C6.84375 7.59375 6.84375 6.4375 7.375 5.5C7.9375 4.59375 8.90625 4 10 4C11.0625 4 12.0312 4.59375 12.5938 5.5C13.125 6.4375 13.125 7.59375 12.5938 8.5C12.0312 9.4375 11.0625 10 10 10ZM8.15625 12.5C6.90625 12.5 5.875 13.375 5.5625 14.5H14.4062C14.0938 13.375 13.0625 12.5 11.8125 12.5H8.15625ZM8.15625 11H11.8125C14.125 11 16 12.875 16 15.1875C16 15.6562 15.625 16 15.1562 16H4.8125C4.34375 16 4 15.625 4 15.1875C4 12.875 5.84375 11 8.15625 11Z"
                        fill="white" />
                </svg>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/classes.scss";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/components/server.scss";
</style>
<script>
export default {
    props: [
        "serverName",
        "serverVersion",
        "battleStatus",
        "lastVipe",
        "maxPlayers",
        "onlinePlayers",
        "shortDesc",
        "serverBackground",
    ],
};
</script>

export default {
    state: {
       openAuthModal: false
    },

    mutations: {
       showModalAuth (state) {
           state.openAuthModal = true;
       },
       closeModal (state) {
           state.openAuthModal = false;
       }
    },
    
    getters: {
        openAuthModal (state) {
            return state.openAuthModal
        }
    }
}

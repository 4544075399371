<template>
    <div class="mw-1560 mx-auto first-cont f-col mail-confirm jc-c">
        <mail-icon />
        <h4>Пожалуйста, подтвердите адрес почты</h4>
        <p>
            Мы отправили письмо на указанный вами адрес, вам нужно перейти по
            ссылке в письме, чтобы завершить регистрацию
        </p>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/registration.scss";

.mail-confirm {
    height: calc(100vh - (86px + 220px + 60px));

    h4 {
        font-size: 28px;
        color: $stWText;
        margin: 20px 0 5px;
    }

    p {
        color: $stWText;
        font-size: 18px;
        max-width: 600px;
        text-align: center;
    }
}
</style>

<script>
import MailIcon from "@/assets/icons/MailIcon.vue";

export default {
    components: { MailIcon },
};
</script>

<template>
    <div class="mw-1560 mx-auto first-cont f-col mail-confirm jc-c">
        <like-icon />
        <h4>Регистрация завершена</h4>
        <p>
            Теперь когда вы закончили регистрацию, вы можете приступить к игре
            на наших серверах.
        </p>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/registration.scss";

.mail-confirm {
    height: calc(100vh - (86px + 220px + 60px));

    h4 {
        font-size: 28px;
        color: $stWText;
        margin: 20px 0 5px;
    }

    p {
        color: $stWText;
        font-size: 18px;
        max-width: 600px;
        text-align: center;
    }
}
</style>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";
import LikeIcon from "@/assets/icons/LikeIcon.vue";

export default {
    components: { LikeIcon },

    setup() {
        return { toast: useToast() };
    },
    data() {
        return {
            info: {
                hash: this.$route.params.hash,
            },
        };
    },
    async mounted() {
        try {
            const { data } = await axios.post(
                `https://api.minexia.ru/v2/register/confirm/`,
                this.info
            ).data;
            console.log("проверка");
            this.$store.commit("userSetInfo", data);
            this.$router.push("/register/confirm/mail");
            this.toast.success("Подтверждение почты пройдено.", {
                position: "bottom-center",
            });
        } catch (e) {
            if (e.response.status === 400) {
                console.log(e);
                this.toast.error(
                    "Что-то пошло не так, обратитесь в группу ВКонтакте или Discord",
                    {
                        position: "bottom-center",
                    }
                );
            }
        }
    },
};
</script>

<template>
    <div class="modal-wrapper f-col" v-if="this.$store.getters.openLauncherModal">
        <div class="modal-bg bg-launcher" @click="closeModal()"></div>
        <div class="modal-container b5">
            <h2 class="modal-launcher-title">Загрузка лаунчера</h2>
            <img src="@/assets/images/close.svg" alt="крестик" class="close-modal" @click="closeLauncherModal" />
            <img src="@/assets/images/ellipse_modal.svg" alt="" style="
                    position: absolute;
                    top: -110px;
                    right: -150px;
                    transform: rotate(192deg);
                " />
            <img src="@/assets/images/ellipse_modal.svg" alt="" style="
                    position: absolute;
                    transform: rotate(64deg);
                    left: -180px;
                    top: -10px;
                " />
            <div class="launchers-container f-col">
                <a href="https://mila.minexia.ru/Minexia-Launcher.jar" download>
                    <div class="launcher-container f-row b5 ali-c" id="java">
                        <img src="@/assets/images/java_icon.svg" alt="" />
                        <p>универсальный лаунчер</p>
                    </div>
                </a>
                <a href="https://mila.minexia.ru/Minexia-Launcher.exe" download>
                    <div class="launcher-container f-row b5 ali-c">
                        <img src="@/assets/images/windows_icon.svg" alt="" />
                        <p>лаунчер для windows</p>
                    </div>
                </a>
            </div>
            <div class="launcher-text-info">
                <span>
                    Для запуска игры Вам необходимо иметь установленную Oracle
                    Java 8 на компьютере. Самую последнюю версию можно загрузить
                    с
                    <a href="https://java.com/ru/download/manual.jsp" class="st-link" target="_blank">официального
                        сайта</a>. На OpenJDK и других версиях Java приложение может
                    работать некорректно.
                </span>
                <br />
                <br />
                <span>
                    Если у вас возникли проблемы, то вы можете написать
                    сообщение в
                    <a href="https://vk.com/minexia" class="st-link" target="_blank">группе vk</a>
                    или упомянуть в своем сообщении администратора в специальном
                    <a href="https://discord.com/invite/nQPnV2bVEr" class="st-link" target="_blank">дискорд канале</a>.
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.modal-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 99;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0px;
}

.modal-bg {
    position: absolute;
    background: rgba(0, 0, 0, 0.34);
    backdrop-filter: blur(13px);
    height: 100%;
    width: 100%;
}

.modal-container {
    margin: auto;
    position: relative;
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    height: fit-content;
    min-height: 400px;
    background: #1d1d1d;
    box-shadow: 0px 0px 174px rgba(255, 70, 70, 0.3);
    align-items: center;
    padding: 25px 0 0 0;
}

.close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 12;
    transition: ease-in 0.2s;
}

.close-modal:hover {
    cursor: pointer;
    transform: scale(1.15) rotate(90deg);
    transition: ease-in 0.1s;
}

.modal-launcher-title {
    font-size: 28px;
    font-weight: 300;
    color: #ffffff;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;
    z-index: 10;
}

.launchers-container {
    gap: 10px;
    margin: 45px 0 0 0;
    z-index: 10;
}

.launcher-container {
    border: 1px solid rgba(255, 70, 70, 0.2);
    box-sizing: border-box;
    transition: ease-in 0.1s;
    height: 90px;
    align-items: center;
    padding: 0 30px 0 30px;
    background: #1d1d1d;
    max-width: 500px;
    margin: 0 auto;
}

.launcher-container>p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    margin: 0 0 0 45px;
}

#java {
    padding-left: 35px !important;
}

.launcher-container:hover {
    box-shadow: 0px 0px 10px 4px rgba(255, 70, 70, 0.21);
    border: 1px solid #ff4646;
    transition: ease-in 0.1s;
}

.launcher-text-info {
    padding: 0 40px;
    margin: 35px 0 35px 0;
    font-family: $stFont;
}

.launcher-text-info>span {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;
}

/* animations */

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* animations */

@media screen and (max-width: 480px) {
    .text-warnings>h3 {
        font-size: 24px;
        margin-bottom: 5px !important;
    }

    .warning-list>li {
        font-size: 12px !important;
    }
}
</style>

<script>
export default {
    methods: {
        closeLauncherModal() {
            this.$store.commit("closeModalLauncher");
            document.body.classList.remove("modal-is-open");
        },
    },
};
</script>

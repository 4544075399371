<template>
    <router-link :to='"/info/server/" + this.serverName' class="server-tab pos-rel of-h">
        <div class="blur pos-abs"></div>
        <div class="text-wrapper pos-rel">
            <span class="f-row">
                <h3>{{ serverName }} - {{ serverVersion }}</h3>
            </span>
            <div class="s-decor-line"></div>
            <p class="server-desc">
                {{ serverDesc }}
            </p>
        </div>
        <img class="pos-abs stImgBack" :src="serverBackground" alt="" />
    </router-link>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/components/server.scss";
</style>
<script>
export default {
    props: ["serverName", "serverVersion", "serverDesc", "serverBackground"],
};
</script>

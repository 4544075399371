<template>
    <router-link :to="/news/ + this.newID" class="new-wrapper stBack f-col"
        v-bind:style="{ backgroundImage: 'linear-gradient(0deg, rgba(40, 41, 44, 0.65), rgba(40, 41, 44, 0.65)),url(' + newPost + ')' }">
        <p class="new-title">{{ newTitle }}</p>
        <p class="new-desc">{{ newShortDesc }}</p>
        <span class="new-date f-row ali-c">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.8 1.09091H10.2V0H9V1.09091H3V0H1.8V1.09091H1.2C0.54 1.09091 0 1.58182 0 2.18182V10.9091C0 11.5091 0.54 12 1.2 12H10.8C11.46 12 12 11.5091 12 10.9091V2.18182C12 1.58182 11.46 1.09091 10.8 1.09091ZM10.8 10.9091H1.2V3.81818H10.8V10.9091Z"
                    fill="#B3B3B3" />
            </svg>
            {{ newDate }}
        </span>
    </router-link>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/IndexView.scss";
</style>

<script>
export default {
    props: ["newTitle", "newShortDesc", "newDate", "newPost", "newID"]
}
</script>

<template>
    <div class="modal-wrapper f-col" v-if="this.$store.getters.openConvertModal">
        <div class="modal-bg bg-launcher" @click="closeConvertModal()"></div>
        <div class="modal-container b5">
            <h2 class="modal-launcher-title">Обмен валюты</h2>
            <img src="@/assets/images/close.svg" alt="крестик" class="close-modal" @click="closeConvertModal" />
            <img src="@/assets/images/ellipse_modal.svg" class="modal-r-c" alt="" style="
                    position: absolute;
                    top: -110px;
                    right: -150px;
                    transform: rotate(192deg);
                " />
            <img src="@/assets/images/ellipse_modal.svg" alt="" class="modal-l-c" style="
                    position: absolute;
                    transform: rotate(64deg);
                    left: -180px;
                    top: -10px;
                " />
            <div class="pays-container f-col">
                <input class="st-input" type="number" placeholder="Введите сумму в рублях которую вы хотите обменять"
                    v-model="convertInfo.count" />
                <button @click="createConvert" class="stButton">Обменять</button>
            </div>
            <div class="pays-container">
                Отсутсвует подходящий метод пополнения баланса? <br />
                Напишите в <a href="">группу вк</a> для подбора подходящего
                метода.
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.modal-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 99;
    left: 50%;
    transform: translate(-50%, 0);
}

.modal-bg {
    position: absolute;
    background: rgba(0, 0, 0, 0.34);
    backdrop-filter: blur(13px);
    height: 100%;
    width: 100%;
}

.modal-container {
    margin: auto;
    position: relative;
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    height: fit-content;
    min-height: 400px;
    background: #1d1d1d;
    box-shadow: 0px 0px 174px rgba(255, 70, 70, 0.3);
    align-items: center;
    padding: 25px 0 0 0;
}

.close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 12;
    transition: ease-in 0.2s;
}

.close-modal:hover {
    cursor: pointer;
    transform: scale(1.15) rotate(90deg);
    transition: ease-in 0.1s;
}

.modal-launcher-title {
    font-size: 28px;
    font-weight: 300;
    color: #ffffff;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;
    z-index: 10;
}

.pays-container {
    gap: 10px;
    margin: 45px 0 0 0;
    z-index: 10;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 30px;
    color: $stWText;
    letter-spacing: 0.01em;
    font-size: 14px;
    text-align: center;

    a {
        color: $stLinkColor;
        transition: $stTransition;

        &:hover {
            color: $stLinkColorH;
        }
    }
}

@media only screen and (max-width: 830px) {
    .modal-container {
        margin: auto 20px;
        width: auto;
    }
}

@media only screen and (max-width: 700px) {
    .modal-launcher-title {
        font-size: 20px;
    }

    .st-input {
        height: 40px;
        font-size: 14px;
    }

    .modal-l-c {
        left: -220px !important;
    }

    .modal-r-c {
        top: -150px !important;
        right: -190px !important;
    }

    .pays-container {
        margin: 30px auto 0;
        padding: 0 30px;
    }
}

@media only screen and (max-width: 540px) {
    .stButton {
        font-size: 14px;
    }

    .pays-container {
        font-size: 12px;
    }

    .st-input {
        font-size: 12px;
    }

    .modal-launcher-title {
        font-size: 18px;
    }
}
</style>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
    setup() {
        return { toast: useToast() };
    },

    data() {
        return {
            convertInfo: {
                count: "",
            },
        };
    },

    methods: {
        closeConvertModal() {
            this.$store.commit("closeConvertModal");
            document.body.classList.remove("modal-is-open");
        },
        async createConvert() {
            try {
                await axios.post(
                    `https://api.minexia.ru/v2/users/${this.$store.getters.whatUserID}/balance/convert`,
                    this.convertInfo,
                    {
                        headers: {
                            authorization: `Bearer ${this.$store.getters.whatUserToken}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                ).then((res) => {
                    window.location.href = res.data.payment;
                });
            } catch (e) {
                if (e.response && e.response.status === 502) {
                    this.toast.error(
                        "Что-то пошло не так, попробуйте позднее!",
                        {
                            position: "bottom-right",
                        }
                    );
                } else {
                    this.toast.error(
                        "Что-то пошло не так, попробуйте позднее! Неизвестная ошибка",
                        {
                            position: "bottom-right",
                        }
                    );
                }
            }
        }
    },
};
</script>

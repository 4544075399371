<template>
    <footer>
        <div class="mw-1560 f-row first-half">
            <div class="logo-container f-col">
                <FooterLogo />
                <p>
                    Приключения уже ждут
                </p>
            </div>
            <div class="fot-nav-container f-col">
                <h4>навигация</h4>
                <ul>
                    <li>
                        <router-link to="/info/about-project">О проекте</router-link>
                    </li>
                    <li><router-link to="/info/vacancy">Вакансии</router-link></li>
                </ul>
            </div>
            <div class="fot-nav-container f-col">
                <h4>информация</h4>
                <ul>
                    <li>
                        <router-link to="/info/polytics">политика конфиденциальности</router-link>
                    </li>
                    <li><router-link to="/info/rules">правила</router-link></li>
                </ul>
            </div>
        </div>
        <div class="mw-1560 f-row second-half">
            <div class="footer-copyright">Minexia 2021-2023.</div>
            <div class="media-container f-row">
                <a href="https://vk.com/minexia"><vk-icon /></a>
                <a href="https://discord.com/invite/nQPnV2bVEr"><ds-icon /></a>
                <a href="https://niitsu.ru/" class="niitsuru-button f-row">Разработано <svg width="20" height="20"
                        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 3.90637H16H20V19.9064H4V16.0938V3.90637Z" fill="#FF4646" />
                        <path d="M16 0.09375H0V16.0938H4V3.90637H16V0.09375Z" fill="white" />
                    </svg>
                    NIITSURU</a>
            </div>
        </div>
    </footer>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/components/footer.scss";
</style>

<script>
import FooterLogo from "@/assets/icons/FooterLogo.vue";
import DsIcon from "@/assets/icons/DsIcon.vue";
import VkIcon from "@/assets/icons/VkIcon.vue";

export default {
    components: { FooterLogo, VkIcon, DsIcon, },
};
</script>

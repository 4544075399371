import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"

import store from '@/stores/index.js'

import ApiPlugin from './plugins/api'

import VueKinesis from "vue-kinesis";

createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .use(ApiPlugin)
    .use(Vuex)
    .use(store)
    .use(Toast)
    .use(VueReCaptcha, { siteKey: '6Lf6jd8fAAAAAG2HcrYdJDHjQ9JW5PEyL0-3nhrV'})
    .use(VueKinesis)
    .mount('#app')

<template>
    <div class="mw-1560 mx-auto first-cont" style="color: #fff; font-size: 24px;">Подтвердите пожалуйста восстановление
        пароля перейдя по ссылке в почте</div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    setup() {
        return { toast: useToast() };
    },
    data() {
        return {
            info: {
                hash: this.$route.params.hash,
            },
        };
    },
    async mounted() {
        try {
            const { data } = await axios.post(
                `https://api.minexia.ru/v2/recovery/confirm/`,
                this.info
            ).data;

            this.$store.commit("userSetInfo", data);
            this.$router.push("/");

            this.toast.success("Ваш новый пароль отправлен вам на почту.", {
                position: "bottom-right",
            });
        } catch (e) {
            if (e.response.status === 400) {
                console.log(e);
            }
        }
    },
};
</script>

<template>
    <div class="mw-1560 first-cont">
        <kinesis-container>
            <div class="server-back pos-rel">
                <picture class="pers-img pos-abs">
                    <source type="image/webp" srcset="
                            @/assets/images/servers/doll_quantum_greeting.webp
                        " />
                    <source type="image/png" srcset="
                            /assets/images/servers/doll_quantum_greeting.png
                        " />
                    <img src="/assets/images/servers/doll_quantum_greeting" alt="Изображение" />
                </picture>
                <kinesis-element :strength="10" class="pos-abs io z5">
                    <img src="@/assets/images/servers/hitech1.png" alt="f" class="" />
                </kinesis-element>
                <kinesis-element :strength="20" class="pos-abs l-forest z5">
                    <img src="@/assets/images/servers/hitech2.png" alt="f" class="" />
                </kinesis-element>
                <kinesis-element :strength="40" class="pos-abs p-thaum z5">
                    <img src="@/assets/images/servers/hitech3.png" alt="f" class="" />
                </kinesis-element>

                <div class="server-title z5 pos-rel">
                    <h3>HiTech 1.7.10</h3>
                    <p>Мир продвинутых технологий</p>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category">Информация о сервере</p>
                    <div class="f-row g15">
                        <div class="server-main-info f-col ali-c bc-red">
                            <p class="main-info-t">последний вайп</p>
                            <p class="desc-main-info">02.12.2023</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">вайп доп.миров</p>
                            <p class="desc-main-info">каждые 30 дней</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">режим</p>
                            <p class="desc-main-info">PVP</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">основной мир</p>
                            <p class="desc-main-info">10 000 x 10 000</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">ад</p>
                            <p class="desc-main-info">5 000 x 5 000</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">край</p>
                            <p class="desc-main-info">5 000 x 5 000</p>
                        </div>
                    </div>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category" :class="{ fullMods: clientMods }"
                        @click="this.clientMods = !this.clientMods">
                        Модификации сервера <arrow-spoiler />
                    </p>
                    <div class="f-row mods-grid" ref="servermods">
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>AE2</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                AE2 | addon <br />
                                AE2 stuff
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                AE2 | addon <br />
                                Wireless Crafting Terminal
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                AE2 | addon <br />
                                Extra Cells 2
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Avaritia</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Avaritia | addon <br />
                                Avaritiaddons
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>IndustrialCraft2</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                AFSU Mod
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                Advanced Solar Panels
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                IC2 Advanced Machines Addon
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                Nuclear Control 2
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                Super Solar Panel
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                Industreal Upgrade
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                EnergyAdditions
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                IndustrialCraft2 | addon <br />
                                Graviation Suite
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Ender IO</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Forestry</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Forestry | addon <br />
                                Extra Bees
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Forestry | addon <br />
                                Extra Trees
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Mekanism</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Mekanism | addon <br />
                                MekanismGenerators
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Mekanism | addon <br />
                                MekanismTools
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thermal Expansion</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thermal Expansion | addon <br />
                                Thermal Foundation
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thermal Expansion | addon <br />
                                Thermal Dynamics
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Chisel</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Carpenter's Blocks</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Better Questing</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Big Reactors</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Botany</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>CustomNpcs</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Compacter</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Draconic Evolution</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Genetics</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Extra Utilites</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Immesrive Engineering</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Iron Chest</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>MineFactory Reloaded</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>OpenBlocks</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>PowerUtils</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Realistic Terrain Generation</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Redstone Arsenal</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Tinkers' Construct</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Treecapitator</p>
                        </div>
                    </div>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category" :class="{ fullMods: serverMods }"
                        @click="this.serverMods = !this.serverMods">
                        Модификации клиента <arrow-spoiler />
                    </p>
                    <div class="f-row mods-grid">
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>OptiFine</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>NEI</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Journey Map</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>ArmorStatusHUD</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>FoamFix</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Inventory Tweaks</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Waila</p>
                        </div>
                    </div>
                </div>
            </div>
        </kinesis-container>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/serverdesc.scss";
</style>

<style lang="scss">
@import "@/assets/scss/decorFiguresFix.scss";
</style>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import ArrowSpoiler from "@/components/icons/ArrowSpoiler.vue";

export default {
    components: {
        ArrowSpoiler,
        KinesisContainer,
        KinesisElement,
    },

    data() {
        return {
            serverMods: false,
            clientMods: false,
        };
    },

    created() {
        document.title = "HiTech - Узнай что такое высокие технологии";
    },
};
</script>

<template>
    <div class="mw-1560 mb-f-f">
        <div class="first-block"></div>
        <div class="title-page">
            <h2>Список серверов</h2>
            <p>
                выберите сервер, который вас заинтересовал и узнайте о нем все
            </p>
        </div>
        <div class="servers-grid">
            <template v-for="server in this.servers" :key="server.id">
                <server-tab :serverName="server.name" :serverVersion="server.serverVersion" :serverDesc="server.serverDesc"
                    :serverBackground="server.serverBackground" />
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/server.scss";
</style>

<script>
import serverTab from "@/components/ServerTab.vue";

export default {
    data() {
        return {
            servers: {
                1: {
                    name: "Mysterium",
                    serverDesc:
                        "Mysterium - магический мир, который шагнул вперед, в эру, похожую на всем известный 'Steam-Punk'.",
                    serverVersion: "1.12.2",
                    serverBackground:
                        "https://cdn.mos.cms.futurecdn.net/rdKdiMcovPGxiBLu7gtbvn.jpg",
                },
                2: {
                    name: "Magic",
                    serverDesc:
                        "Magic - более древний и всеми забытый магический мир, прогресс был остановлен, ничего нового не изобретается уже сотни лет",
                    serverVersion: "1.7.10",
                    serverBackground:
                        "https://staticg.sportskeeda.com/editor/2022/01/452b9-16418477870742-1920.jpg",
                },
                3: {
                    name: "HiTech",
                    serverDesc:
                        "HiTech - Мир высоких технологий (Не зря же в названии слово Hi), с усложненными крафтами и большим количеством механизмов",
                    serverVersion: "1.7.10",
                    serverBackground:
                        "https://i0.wp.com/minecraftshader.com/wp-content/uploads/2022/02/RedHat-Shaders-2.png?fit=1024%2C576&ssl=1",
                },
                4: {
                    name: "TechnoMagicRPG",
                    serverDesc:
                        "Здесь вы встретите все, и даже то, что не видели, огромных монстров, боссов, технологии и магию. Постройте ферму для фарма лута с боссов, или аспектов для своих изучений",
                    serverVersion: "1.7.10",
                    serverBackground:
                        "https://i0.wp.com/minecraftshader.com/wp-content/uploads/2022/02/RedHat-Shaders-2.png?fit=1024%2C576&ssl=1",
                },
            },
        };
    },

    components: {
        serverTab,
    },

    created() {
        document.title = "Minexia - список серверов";
    },
};
</script>

export default {
    state: {
       openLauncherModal: false
    },

    mutations: {
       showModalLauncher (state) {
           state.openLauncherModal = true;
       },
       closeModalLauncher (state) {
           state.openLauncherModal = false;
       }
    },
    
    getters: {
        openLauncherModal (state) {
            return state.openLauncherModal
        }
    }
}

<template>
    <div>
        <section class="first-screen stBack f-row pos-rel">
            <div class="mw-1560 first-cont">
                <div class="main-text f-col">
                    <h3>MINEXIA</h3>
                    <p>
                        Увлекательные приключения с друзьями уже ждут! <br />
                        Встреть огромные данжи, различных боссов <br />
                        Путешествуй по разным мирам
                    </p>
                </div>
                <div class="start-game f-row">
                    <router-link to="" class="stButton start-game-link">Начать игру</router-link>
                    <div class="stButton indexDownload f-row ali-c" @click="openLauncherModal">
                        Скачать лаунчер
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path
                                d="M7.875 0.875V7.51953L9.87109 5.52344C10.1992 5.16797 10.7734 5.16797 11.1016 5.52344C11.457 5.85156 11.457 6.42578 11.1016 6.75391L7.60156 10.2539C7.27344 10.6094 6.69922 10.6094 6.37109 10.2539L2.87109 6.75391C2.51562 6.42578 2.51562 5.85156 2.87109 5.52344C3.19922 5.16797 3.77344 5.16797 4.10156 5.52344L6.125 7.51953V0.875C6.125 0.410156 6.50781 0 7 0C7.46484 0 7.875 0.410156 7.875 0.875ZM1.75 9.625H4.51172L5.74219 10.8828C6.42578 11.5664 7.54688 11.5664 8.23047 10.8828L9.46094 9.625H12.25C13.207 9.625 14 10.418 14 11.375V12.25C14 13.2344 13.207 14 12.25 14H1.75C0.765625 14 0 13.2344 0 12.25V11.375C0 10.418 0.765625 9.625 1.75 9.625ZM11.8125 11.1562C11.4297 11.1562 11.1562 11.457 11.1562 11.8125C11.1562 12.1953 11.4297 12.4688 11.8125 12.4688C12.168 12.4688 12.4688 12.1953 12.4688 11.8125C12.4688 11.457 12.168 11.1562 11.8125 11.1562Z"
                                fill="#FF4646" />
                        </svg>
                    </div>
                </div>
                <div class="online-wrapper f-row ali-c">
                    <div class="status pos-rel f-col ali-c">
                        <div class="status-border pos-abs"></div>
                        <div class="status-core pos-abs"></div>
                    </div>
                    <p class="online-counter">
                        Игроков онлайн: {{ this.fullOnline }}
                    </p>
                </div>
                <div class="soc-links-main f-row pos-abs">
                    <a href="https://vk.com/minexia">
                        <svg class="wc-sc" width="35" height="35" viewBox="0 0 33 33" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M1.24702e-05 4.60635L0 15.4289L2.34091e-05 16.7705L3.04756e-05 27.5931C3.34146e-05 30.1384 2.06178 32.1994 4.60709 32.1994H14.7278H16.1304H27.653C30.1983 32.1994 32.2609 30.1384 32.2609 27.5931V16.7705V15.4289V4.49607C32.2609 1.95076 30.1983 -2.86245e-06 27.653 0L16.1304 7.33219e-06H14.7278H4.60707C2.06176 7.33219e-06 1.76567e-05 2.06104 1.24702e-05 4.60635ZM5.64574 10.3081H8.06586C8.68438 10.3081 8.91707 10.5788 9.149 11.2488C10.3327 14.6959 12.3284 17.7085 13.1488 17.7085C13.4565 17.7085 13.5976 17.5668 13.5976 16.7856V13.2225C13.5421 12.2083 13.1877 11.7697 12.9252 11.4448C12.763 11.2442 12.636 11.087 12.636 10.8643C12.636 10.5936 12.8673 10.3081 13.2518 10.3081H17.0549C17.5678 10.3081 17.7463 10.5823 17.7463 11.1976V15.991C17.7463 16.5036 17.969 16.683 18.1229 16.683C18.4305 16.683 18.687 16.5036 19.2511 15.9397C20.6285 14.4006 21.6858 12.2055 22.069 11.3515C22.1763 11.1125 22.2572 10.8562 22.4281 10.6575C22.6033 10.4537 22.8665 10.3081 23.2781 10.3081H25.6982C26.4301 10.3081 26.5839 10.6848 26.4301 11.1976C26.2171 12.1733 24.7393 14.4627 23.8493 15.7801C23.4049 16.438 22.6864 17.21 23.1738 17.8366V17.8366C23.3003 18.0135 23.57 18.2776 23.8786 18.5799C24.1959 18.8907 24.5544 19.2418 24.8404 19.5797C25.88 20.7489 26.6635 21.7353 26.8817 22.4151C27.0812 23.0972 26.7354 23.4429 26.0439 23.4429H23.6238C22.9756 23.4429 22.6493 23.0799 21.9439 22.2955C21.6449 21.963 21.2779 21.5548 20.7894 21.0664C19.3536 19.6822 18.7382 19.5027 18.3792 19.5027C17.8922 19.5027 17.7464 19.6407 17.7464 20.332V22.5019C17.7464 23.0972 17.5561 23.4429 16.0177 23.4429C13.4538 23.4429 10.6361 21.8866 8.63626 19.0158C5.63645 14.8118 4.81598 11.6333 4.81598 10.9924C4.81598 10.6336 4.95427 10.3081 5.64574 10.3081Z"
                                fill="#ffffff9c" fill-opacity="0.92" />
                        </svg>
                    </a>
                    <a href="https://discord.com/invite/nQPnV2bVEr">
                        <svg class="wc-sc" width="35" height="40" viewBox="0 0 34 37" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.5366 15.4688C12.4858 15.4688 11.6562 16.3905 11.6562 17.515C11.6562 18.6395 12.5043 19.5613 13.5366 19.5613C14.5874 19.5613 15.4169 18.6395 15.4169 17.515C15.4354 16.3905 14.5874 15.4688 13.5366 15.4688ZM20.2653 15.4688C19.2145 15.4688 18.3849 16.3905 18.3849 17.515C18.3849 18.6395 19.2329 19.5613 20.2653 19.5613C21.3161 19.5613 22.1456 18.6395 22.1456 17.515C22.1456 16.3905 21.3161 15.4688 20.2653 15.4688Z"
                                fill="#ffffff9c" fill-opacity="0.92" />
                            <path
                                d="M29.2239 0H4.52132C2.43819 0 0.742188 1.696 0.742188 3.79756V28.7214C0.742188 30.823 2.43819 32.519 4.52132 32.519H25.4264L24.4493 29.1085L26.809 31.3023L29.0396 33.367L33.0031 36.8696V3.79756C33.0031 1.696 31.3071 0 29.2239 0ZM22.1081 24.0758C22.1081 24.0758 21.4444 23.2831 20.8914 22.5826C23.3064 21.9005 24.2281 20.3889 24.2281 20.3889C23.4723 20.8866 22.7533 21.2369 22.1081 21.4765C21.1864 21.8637 20.3015 22.1217 19.4351 22.2692C17.6653 22.601 16.0431 22.5089 14.6604 22.2508C13.6097 22.048 12.7064 21.753 11.9505 21.4581C11.5265 21.2922 11.0657 21.0894 10.6048 20.8313C10.5495 20.7944 10.4942 20.776 10.4389 20.7391C10.402 20.7207 10.3836 20.7023 10.3651 20.6838C10.0333 20.4995 9.84897 20.3704 9.84897 20.3704C9.84897 20.3704 10.7338 21.8452 13.0751 22.5457C12.522 23.2463 11.8399 24.0758 11.8399 24.0758C7.76584 23.9468 6.21732 21.2737 6.21732 21.2737C6.21732 15.3377 8.87193 10.5263 8.87193 10.5263C11.5265 8.5353 14.0521 8.59061 14.0521 8.59061L14.2364 8.81183C10.9182 9.77043 9.3881 11.2268 9.3881 11.2268C9.3881 11.2268 9.79367 11.0056 10.4758 10.6922C12.4483 9.82574 14.0152 9.58609 14.6604 9.53078C14.7711 9.51235 14.8632 9.49391 14.9738 9.49391C16.0984 9.34643 17.3704 9.30957 18.6977 9.45704C20.449 9.65983 22.3293 10.176 24.2465 11.2268C24.2465 11.2268 22.7902 9.84417 19.6563 8.88557L19.9144 8.59061C19.9144 8.59061 22.4399 8.5353 25.0945 10.5263C25.0945 10.5263 27.7491 15.3377 27.7491 21.2737C27.7491 21.2737 26.1822 23.9468 22.1081 24.0758Z"
                                fill="#ffffff9c" fill-opacity="0.92" />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="bottom-arrow pos-abs">
                <svg width="12" height="42" viewBox="0 0 12 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 42L11.7735 32L0.226497 32L6 42ZM7 33L7 -4.3769e-08L5 4.3769e-08L5 33L7 33Z" fill="white"
                        fill-opacity="0.57" />
                </svg>
            </div>
        </section>
        <section class="second-screen stBack f-col z10 pos-rel">
            <div class="mw-1560 second-cont">
                <div class="st-separator news-separator">
                    <p>новости проекта</p>
                    <div class="news-desk f-row sp-b">
                        <p>Ознакомиться с новостями в мельчашийших подробностях можно ниже</p>
                        <router-link to="/" class="all-news-link">Смотреть все
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.6"
                                    d="M12.75 1.5V10.25C12.75 10.9531 12.1641 11.5 11.5 11.5C10.7969 11.5 10.25 10.9531 10.25 10.25V4.54688L2.35938 12.3984C1.89062 12.9062 1.07031 12.9062 0.601562 12.3984C0.09375 11.9297 0.09375 11.1094 0.601562 10.6406L8.45312 2.75H2.75C2.04688 2.75 1.5 2.20312 1.5 1.5C1.5 0.835938 2.04688 0.25 2.75 0.25H11.5C12.1641 0.25 12.75 0.835938 12.75 1.5Z"
                                    fill="white" />
                            </svg>
                        </router-link>
                    </div>
                </div>
                <div class="news">
                    <div class="news-container">
                        <template v-for="post in this.posts" :key="post.id">
                            <newPost :newTitle="post.title" :newShortDesc="post.short_text"
                                :newDate="WhenPublished(post.posted)" :newPost="post.short_image" :newID="post.id" />
                        </template>
                    </div>
                </div>
            </div>
        </section>
        <section class="third-screen stBack f-col pos-rel z10">
            <div class="mw-1560 second-cont">
                <div class="st-separator news-separator">
                    <p>новости проекта</p>
                    <div class="news-desk f-row sp-b">
                        <p>Обширное количество серверов с различными мирами</p>
                    </div>
                </div>
                <div class="servers">
                    <template v-for="server in this.servers" :key="server.id">
                        <router-link to="/servers">
                            <the-server :serverVersion="server.version" :battleStatus="server.battleMode"
                                :lastVipe="server.vipe" :serverBackground="server.background" :serverName="server.name"
                                :shortDesc="server.serverDesc" :onlinePlayers="server.online" />
                        </router-link>
                    </template>
                </div>
            </div>
        </section>
        <section class="fourth-screen stBack f-col pos-rel z10">
            <div class="mw-1560 f-col">
                <div class="st-separator news-separator">
                    <p>новости проекта</p>
                    <div class="news-desk f-row sp-b">
                        <p>Обширное количество серверов с различными мирами</p>
                    </div>
                </div>
                <div class="vote-grid">
                    <div class="why-vote big-v-block vote-block pos-rel">
                        <p class="why-title">зачем мне голосовать за проект?</p>
                        <span class="why-response">
                            Высокий рейтинг в топах, один из источников новых
                            игроков на проекте, голосуя за него, вы помогаете
                            нам развиваться и привлекать новых игроков, которые
                            могут стать вашими новыми друзьями, или соперниками.
                            <br />
                            <br />
                            За каждый голос начисляется награда и балл рейтинга,
                            награда сразу же придет на ваш счет и ее можно будет
                            потратить, баллы рейтинга нужны для соревнования
                            между другими игроками.
                        </span>
                    </div>

                    <div class="rating-score big-v-block vote-block pos-rel">
                        <p class="why-title">баллы рейтинга?</p>
                        <span class="why-response">
                            Хочешь выиграть награду в конце месяца? Голосуй
                            каждый день, получай баллы и учавствуй в рейтинге
                            лучших голосующих. <br /><br />
                            Награды в конце месяца получают первые ТОП 3 игрока,
                            которые каждый день голосовали и поддерживали наш
                            проект.
                        </span>
                    </div>
                    <a href="https://mctop.su/servers/6604/" target="_blank" class="rating-block mctop vote-block f-col">
                        <img src="@/assets/images/ratings/mctop.png" alt="" class="rating-logo" />
                        <div class="vote-t-wrapper">
                            <p class="rating-title">голосуй на mctop</p>
                            <span class="rating-desc"></span>
                        </div>
                    </a>
                    <a href="https://topcraft.ru/servers/11385/" target="_blank"
                        class="rating-block topcraft vote-block f-col">
                        <img src="@/assets/images/ratings/topcraft.png" alt="" class="rating-logo" />
                        <div class="vote-t-wrapper">
                            <p class="rating-title">голосуй на topcraft</p>
                            <span class="rating-desc"></span>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/IndexView.scss";
</style>

<script>
import newPost from "@/components/newPost.vue";
import TheServer from "@/components/TheServer.vue";
import { DateTime } from "luxon";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
            eventBackground:
                "https://cdn.discordapp.com/attachments/953923217393877002/979670980047798292/2022-03-22_23.53.19.png",
            newPost:
                "https://cdn.discordapp.com/attachments/953923217393877002/976131652734574592/unknown.png",
            posts: {},
            serverOnline: {},
            servers: {},
            fullOnline: 0,
        };
    },

    setup() {
        return { toast: useToast() };
    },

    props: ["modal-is-open"],

    methods: {
        openLauncherModal() {
            this.$store.commit("showModalLauncher");
            document.body.classList.add("modal-is-open");
        },
        WhenPublished(time) {
            const myDateTime = DateTime.fromSeconds(time);
            const myDateTimeISO = myDateTime.toISO();
            return DateTime.fromISO(myDateTimeISO).toLocaleString(
                "dd LLL yyyy, HH:MM"
            );
        },
    },

    mounted() {
        this.$nextTick(function () {
            axios
                .get("https://api.minexia.ru/v2/news?page=1&size=4")
                .then((response) => {
                    this.posts = response.data.news;
                })
                .catch((e) => {
                    if (e.response.status === 500) {
                        this.toast.error(
                            "Что-то пошло не так, попробуйте позднее! (>ᆺ>)",
                            {
                                position: "bottom-right",
                            }
                        );
                    }
                });
        });
        this.$nextTick(function () {
            axios
                .get("https://api.minexia.ru/v2/monitoring")
                .then((response) => {
                    this.servers = response.data.servers;
                    const test = this.servers;
                    let sum = 0;
                    test.forEach(function (entry) {
                        sum = sum + entry.online;
                    });
                    this.fullOnline = sum;
                })
                .catch((e) => {
                    if (e.response.status === 500) {
                        this.toast.error(
                            "Данные о серверах не были получены...",
                            {
                                position: "bottom-right",
                            }
                        );
                    }
                });
        });
        this.$nextTick(function () {
            setInterval(() => {
                axios
                    .get("https://api.minexia.ru/v2/monitoring")
                    .then((response) => {
                        this.servers = response.data.servers;
                        const test = this.servers;
                        let sum = 0;
                        test.forEach(function (entry) {
                            sum = sum + entry.online;
                        });
                        this.fullOnline = sum;
                    })
                    .catch((e) => {
                        if (e.response.status === 500) {
                            this.toast.error(
                                "Данные о серверах не были получены...",
                                {
                                    position: "bottom-right",
                                }
                            );
                        }
                    });
            }, 10000);
        });
    },

    components: {
        newPost,
        TheServer,
    },
};
</script>

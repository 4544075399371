<template>
    <div class="mw-1560 mb-f-f">
        <div class="first-block"></div>
        <div class="title-page">
            <h2>Политика конфиденциальности</h2>
            <p>Политика в отношении обработки персональных данных</p>
        </div>
        <div class="only-text-container">
            <h2 class="sub-title-static-text">Введение</h2>
            <span>Настоящая Политика конфиденциальности применима к веб-сайту
                расположенному по адресу:
                <a href="https://minexia.ru" class="st-link">https://minexia.ru</a>, который получает данные от
                пользователей и имеет ссылку на
                настоящую Политику конфиденциальности.</span>
            <br />
            <span>Настоящая Политика конфиденциальности содержит сведения об
                обработке и защите ваших персональных данных при использовании
                веб-сайта. Мы уделяем особое внимание сохранению
                конфиденциальности данных, по этой причине мы стремимся
                обеспечивать защиту ваших персональных данных во время их
                обработки. Мы рекомендуем Вам посещать этот веб-сайт регулярно,
                поскольку настоящая Политика конфиденциальности может
                обновляться в соответствии с поправками в законодательстве или
                изменениями в наших внутренних процессах. Если у вас есть
                вопросы или замечания о Политике конфиденциальности или вы
                хотите обновить предоставленную нам информацию или предпочтения,
                обратитесь к нам по e-mail:
                <a href="mailto:admin@minexia.ru" class="st-link">admin@minexia.ru</a></span>
            <h2 class="sub-title-static-text">Общая информация</h2>
            Как правило, вы можете посещать большинство страниц нашего веб-сайта
            и читать размещенную на них информацию без предоставления каких-либо
            персональных данных. <br /><br />
            Чтобы извлекать запрашиваемую вами информацию с наших веб-сайтов,
            серверы сохраняют в анонимной форме некоторые данные в целях
            предоставления услуг и/или в статистических целях и целях
            обеспечения безопасности. В этом случае сохраняются общие сведения,
            например, доменное имя или тип браузера. Эти данные не могут быть
            использованы нами для вашей идентификации. После проведения
            статистической оценки эти анонимные данные сразу же удаляются.
            <br /><br />
            Мы не обрабатываем "персональные данные особых категорий" (сведения
            о расовой и этнической принадлежности, религиозных или философских
            убеждениях, сексуальной жизни, сексуальной ориентации, политических
            предпочтениях, участии в профессиональных союзах, а также,
            генетические и биометрические данные).
            <h2 class="sub-title-static-text">
                Почему мы обрабатываем ваши персональные данные
            </h2>
            Цели, которые мы преследуем при обработке предоставляемых вами
            персональных данных, это предоставление услуг и функций, которые вы
            используете. В частности, мы используем данные для следующих целей:
            <ul class="static-list">
                <li>
                    обеспечение выполнения договоров с пользователями,
                    реализация необходимых услуг для клиентов;
                </li>
                <li>
                    предоставление технической поддержки по продуктам и услугам
                    для клиентов и повышение качества продуктов и услуг;
                </li>
                <li>реагирование на ваши вопросы и Взаимодействие с вами;</li>
                <li>
                    обеспечение безопасности наших веб-сайтов, защита вас и нас
                    от мошенничества;
                </li>
                <li>
                    повышение удобства работы и взаимодействия с услугами для
                    пользователей, в частности, модернизация интерфейсов и
                    предоставление необходимого контента и рекламы в
                    маркетинговых целях.
                </li>
            </ul>
            <h2 class="sub-title-static-text">
                Юридическое основание для обработки ваших персональных данных
            </h2>
            Наши законные основания для обработки ваших основных персональных
            данных:
            <ul class="static-list">
                <li>
                    Обработка данных, необходимая для выполнения договора с
                    субъектом данных или осуществления необходимых действий для
                    заключения договора.
                </li>
            </ul>
            Если это обусловлено нашими законными интересами, которым не
            противоречат ваши интересы и фундаментальные права:
            <ul class="static-list">
                <li>
                    Обеспечение постоянной безопасности и нормальной работы
                    нашего веб-сайта и связанных ИТ-систем, а также защита от
                    мошенничества.
                </li>
                <li>
                    Ограниченные персональные данные хранятся в том случае, если
                    пользователи запретили нам обрабатывать их персональные
                    данные – это позволяет выполнять требования пользователей.
                </li>
                <li>
                    Ваше особое согласие на обработку данных в указанных Целях.
                </li>
                <li>
                    Обработка, необходимая для выполнения юридических
                    обязательств.
                </li>
            </ul>
            <h2 class="sub-title-static-text">
                Автоматизированное принятие решений
            </h2>
            Мы не используем автоматизированное принятие решений для нашего
            Веб-сайта.
            <h2 class="sub-title-static-text">
                Какие персональные данные мы обрабатываем и как мы их используем
            </h2>
            <b class="not-full-line">Категории персональных данных</b>
            Для использования сайта и получения доступа ко всем его функциям вы
            предоставляете следующие персональные данные:
            <ul class="static-list">
                <li>Ваши адрес электронной почты и пароль;</li>
                <li>Ваши Ф.И.О.;</li>
                <li>Паспортные данные;</li>
                <li>Номер мобильного телефона;</li>
                <li>Местожительства</li>
                <li>
                    Статистические сведения о вашем браузере (имена доменов;
                    IP-адреса; поставщик сетевых услуг; типы браузеров и другие
                    данные о клиентской среде; веб-страница, которую вы посетили
                    непосредственно перед переходом на наш веб-сайт; страницы
                    нашего веб-сайта, которые вы посетили; время, проведенное на
                    этих страницах; сведения, которые вы искали на нашем
                    веб-сайте; время и даты доступа; а также другие
                    статистические данные от пользователей, посетивших наш
                    веб-сайт);
                </li>
            </ul>
            <h2 class="sub-title-static-text">Описание Сайта</h2>
            <span class="mb-5"><b class="not-full-line">Регистрация учетной записи.</b> Чтобы
                использовать сайт и иметь доступ ко всем ее функциям, вам
                потребуется создать личную учетную запись. Для этого необходимо
                ввести адрес электронной почты (который будет использоваться в
                качестве вашего имени пользователя) и пароль. Мы обязуемся
                обрабатывать и использовать эту информацию только для
                предоставления вам функциональности сайта. Эта информация будет
                обработана и использована в иных целях, только если вы дадите на
                это свое прямое согласие (например, на получение актуальных
                новостей о продуктах и услугах по электронной почте). Вам также
                может быть необходимо предоставить нам номер своего мобильного
                телефона для дополнительной защиты вашей учетной записи от
                несанкционированного доступа. Мы будем использовать номер вашего
                мобильного телефона только для отправки кодов безопасности для
                авторизации и для связи с вами в случае необходимости.</span>
            <span class="mb-5"><b class="not-full-line">Параметры учетной записи и история покупок.</b>
                Вы можете просматривать, обновлять и исправлять данные вашей
                учетной записи, пароль и предпочтения, а также удалить свою
                учетную запись в любое время с помощью функции "Параметры
                учетной записи". Кроме того, вы можете просматривать свою
                историю покупок на нашем сайте. Вы также можете привязать свою
                банковскую карту для удобного приобретения дополнительных
                продуктов и услуг. При активации этой функции поставщик услуг
                интернет-магазина привяжет данные вашей банковской карты к
                учетной записи и сохранит их для будущих заказов. Будет создана
                особая ссылка для вашей учетной записи у поставщика услуг
                интернет-магазина и будет размещать отправляемые от вашего имени
                заказы. Поставщик услуг интернет-магазина также сообщит нам
                информацию о дате окончания срока действия вашей банковской
                карты и последние 4 цифры номера банковской карты. Однако мы не
                будем иметь доступ к остальным данным вашей банковской карты,
                сохраненным поставщиком услуг интернет-магазина. Привязка к
                вашей учетной записи осуществляется только с использованием
                технических средств с помощью комбинации внутреннего
                идентификатора пользователя и пароля. Эти учетные данные не
                предоставляют нам доступа к данным банковской карты и другим
                персональным данным, сохраненным поставщиком услуг
                интернет-магазина. Внутренний идентификатор пользователя
                используется для создания заказов, размещенных вами в
                интернет-магазине.</span>
            <span class="mb-5"><b>Поддержка.</b> При вашем обращении в Службу технической
                поддержки с помощью служб поддержки мы запросим у вас сведения,
                необходимые для предоставления поддержки (например, ваш номер
                абонемента, последние 4 цифры вашей банковской карты,
                использованной для приобретения услуг, адрес электронной почты,
                а также информацию о продукте, с которым возникла проблема,
                аппаратном и программном обеспечении компьютера и описание
                проблемы).</span>
            <span class="mb-5"><b>Статистическая информация.</b> Мы можем отслеживать имена
                доменов, IP-адреса, поставщиков сетевых услуг, типы браузеров и
                другие данные о клиентской среде (например, модель вашего
                устройства, операционную систему, разрешение экрана и т. д.),
                получаемые от пользователей, посещающих наш веб-сайт. Мы также
                можем обрабатывать информацию о том, какие услуги вы используете
                и как вы их используете; страницах нашего веб-сайта, которые вы
                посетили; времени, проведенном на этих страницах; сведениях,
                которые вы искали на нашем веб-сайте; времени и даты доступа; а
                также другие статистические данные. Мы используем эти данные для
                составления статистики трафика для наших веб-сайтов, а также для
                повышения безопасности и доступности наших веб-сайтов и услуг.
                Эти сведения не связаны с какими-либо персональными данными. Мы
                не запрашиваем, не отслеживаем и не получаем доступа к
                каким-либо сведениям о вашем местоположении.</span>
            <span class="mb-5"><b>Новостная рассылка и специальные предложения.</b> Если вы
                подписались на данные услуги и предоставили нам свой адрес
                электронной почты, а также, в некоторых случаях, имя и фамилию,
                мы будем использовать эти данные, чтобы предоставить вам
                дополнительную информацию о продуктах и услугах и специальных
                предложениях, которые могут представлять для вас интерес.
                Подписка является добровольной, и вы можете отписаться в любое
                время, пройдя по соответствующей ссылке в письме, приведенной в
                каждом письме. В этом случае ваше имя и адрес электронной почты
                будут удалены, и мы больше не будем отправлять вам такие
                сообщения, пока вы не зарегистрируетесь снова.</span>
            <span class="mb-5"><b>Формы обратной связи.</b> Если вы хотите связаться с нами,
                используя формы обратной связи и формы для получения поддержки,
                вам будет необходимо предоставить свои контактные данные (поля
                ввода с пометкой "*"), которые мы будем обрабатывать или
                использовать по мере необходимости, чтобы связаться с вами и
                предоставить вам информацию, которую вы хотите получить. Вы
                можете предоставить дополнительную информацию (заполнив поля
                данных, не отмеченных "*"), которую мы будем обрабатывать в
                соответствии со всеми установленными требованиями к защите
                данных.</span>
            Время, в течение которого мы храним ваши персональные данные
            <br />
            Данные удаляются не позднее чем через 1 (один) календарный месяц
            после удаления учетной записи. <br />
            <b class="mb-5 mt-5">Chat</b>
            <b class="mb-5">Категории персональных данных</b>
            Мы обрабатываем персональные данные, которые вы можете предоставить
            нам для оказания вам услуг и выполнения наших обязательств. В
            частности, к этим данным относятся:
            <ul class="static-list">
                <li>
                    данные сеанса чата, такие как ваш IP-адрес, время и данные
                    чатов, а также содержимое чата и отправленные файлы,
                    необходимые, чтобы оказать вам услугу;
                </li>
                <li>
                    содержимое чатов с командой поддержки (история чатов) и
                    отправленные файлы;
                </li>
                <li>адрес электронной почты.</li>
                <li>
                    Время, в течение которого мы храним ваши персональные данные
                </li>
            </ul>
            Мы удаляем персональные данные в соответствии с требованиями
            законодательства. Данные истории чата и отправленные файлы, будут
            удалены через 90 дней после сбора или по вашему запросу.
            <h2 class="sub-title-static-text">
                <b>Используем ли мы файлы cookie или модули для социальных
                    сетей</b>
            </h2>
            Файлы cookie представляют собой файлы или фрагменты информации,
            которые могут быть сохранены на вашем компьютере или других
            Интернет-совместимых устройствах (например, смартфон или планшет и
            др.), когда вы посещаете наш веб-сайт. Эти данные зачастую состоят
            из буквенно-цифровых строк, которые обеспечивают уникальную
            идентификацию вашего компьютера, но могут содержать и иные сведения.
            <br />
            <br />
            Мы используем различные типы файлов cookie (небольшие текстовые
            файлы, которые хранятся на вашем устройстве):
            <ul class="static-list">
                <li>файлы cookie пользовательского ввода;</li>
                <li>аутентификационные файлы cookie;</li>
                <li>файлы cookie с вашими предпочтениями и параметрами;</li>
                <li>файлы cookie производительности;</li>
                <li>
                    файлы cookie, связанные с обеспечением безопасности
                    (противодействием мошенничеству);
                </li>
                <li>рекламные файлы cookie третьих лиц;</li>
                <li>собственные аналитические файлы cookie;</li>
                <li>отслеживающие файлы cookie социальных сетей.</li>
                <li>
                    Также мы используем файлы cookie на нашем веб-сайте, когда
                    вы оформляете услугу или иной продукт на нашем сайте. Это
                    позволяет нам повысить удобство взаимодействия пользователей
                    с веб-сайтом и продуктом.
                </li>
            </ul>
            На некоторых наших веб-страницах используются электронные метки
            (веб-маяки), которые мы используем для получения комплексной
            статистики в отношении эффективности наших рекламных кампаний и иных
            операций. Также веб-маяки мы включаем в рассылаемые вам электронные
            письма для определения эффективности, результативности и оптимизации
            почтовых рассылок и пользовательского опыта. Для этих целей мы
            используем различные аналитические службы. <br />
            <br />
            Вы можете настроить параметры браузера таким образом, чтобы файлы
            cookie блокировались или ваша система, сообщала вам, когда веб-сайт
            хочет установить файлы cookie. Обратите внимание, что блокирование
            файлов cookie может привести к тому, что вы не сможете использовать
            все функции нашего веб-сайта. <br />
            <br />
            Мы также используем различные типы "модулей для социальных сетей".
            <h2 class="sub-title-static-text">Принципы защиты данных</h2>
            Как правило, наши веб-сайты разрабатываются таким образом, что
            обработка персональных данных сводится к минимуму. В некоторых
            случаях нам приходится передавать ваши персональные данные третьим
            лицам в соответствии с требованиями действующего законодательства.
            Это, например, происходит, когда есть основания подозревать, что
            совершено преступление, или что наш веб-сайт используется
            неправильно. <br />
            <br />
            Для оказания определенных услуг мы можем привлекать внешних
            поставщиков услуг. Если таким внешним поставщикам услуг необходим
            доступ к персональным данным, мы проследим, чтобы такой доступ был
            ограничен в объеме, необходимом для оказания соответствующих услуг.
            Кроме того, такие внешние поставщики услуг должны самостоятельно
            исполнять все действующие правила защиты данных. <br />
            <br />
            На наших веб-сайтах содержатся ссылки на компании, с которыми мы
            поддерживаем деловые отношения. Мы не несем ответственность за
            соблюдение требований по защите данных в отношении использования
            веб-сайтов наших партнеров. Для получения информации о защите данных
            при посещении этих веб-сайтов, обратитесь к политике
            конфиденциальности веб-сайта соответствующих компаний.
            <h2 class="sub-title-static-text">
                Получатели и правовая база для передачи
            </h2>
            Мы не предоставляем данные или доступ к данным государственным
            организациям или третьим лицам. Мы раскрываем информацию с
            ограничениями доступа только следующим образом: <br />
            <br />
            Мы также можем передавать ваши данные поставщикам, которые помогают
            нам оказывать услуги. Они будут обрабатывать ваши персональные
            данные в качестве обработчиков данных (статья 28 GDPR) исключительно
            от нашего имени. К категориям таких получателей относятся:
            веб-аналитика, обработка данных, реклама, рассылки по электронной
            почте, обработка платежей, выполнение заказов и другие услуги.
            <br />
            <br />
            В таких случаях эти компании должны соблюдать наши требования к
            конфиденциальности и безопасности данных и не могут использовать
            персональные данные, которые они от нас получают, для других целей.
            <h2 class="sub-title-static-text">
                Ваши права и ваши персональные данные и способы их реализации
            </h2>
            Мы также сообщаем, что у вас есть определенные права в отношении
            персональных данных, которые мы храним: <br /><br />
            Право на получение информации. Вы имеете право на получение ясной,
            прозрачной и понятной информации о том, как мы используем ваши
            персональные данные, и о ваших правах. <br /><br />
            Право доступа. Это право позволяет вам получить копию персональных
            данных, которые мы обрабатываем, и проверить, что мы обрабатываем их
            в соответствии с действующим законодательством. <br /><br />
            Право на внесение изменений. Вы можете отправить запрос на внесение
            изменений в ваши персональные данные, которые мы обрабатываем.
            <br /><br />
            Право на удаление (Право на забвение). Это право позволяет вам
            попросить нас удалить или стереть персональные данные, если у нас
            нет веских причин продолжать их обработку. <br /><br />
            Право на ограничение обработки. Это право позволяет вам попросить
            нас приостановить обработку ваших персональных данных, например,
            если вы хотите, чтобы мы установили их точность или причину
            обработки. <br /><br />
            Право на переносимость данных. Вы можете попросить нас перенести
            ваши персональные данные. Мы предоставим вам или третьей стороне,
            которую вы выбрали, ваши персональные данные в структурированном
            стандартном машиночитаемом формате. <br /><br />
            Право на возражение. Вы можете в любое время возразить против
            обработки ваших персональных данных, если такая обработка
            соответствует нашим законным интересам. <br /><br />
            Право отозвать согласие на защиту данных. Если вы отзовете свое
            согласие, мы не сможем предоставить вам доступ к определенным
            функциям нашего веб-сайта. Мы сообщим вам об этом в случае и во
            время отзыва вашего согласия. <br /><br />
            Право обратиться с жалобой. Вы имеете право обратиться в орган по
            защите данных вашей страны, чтобы обжаловать наши методы защиты
            данных и конфиденциальности. <br /><br />
            Право деактивировать файлы cookie. Если вы хотите ограничить или
            заблокировать все файлы cookie, установленные нашими веб-сайтами,
            используйте инструмент, доступный на соответствующем веб-сайте (если
            это применимо), или обратитесь к справке вашего браузера, чтобы
            узнать, как управлять параметрами браузера. <br /><br />
            Если вы хотите воспользоваться этими правами, вы можете в любое
            время связаться с нами. <br /><br />
            Как правило, вам не придется оплачивать доступ к вашим персональным
            данным (или реализацию каких-либо других прав). Однако, за
            исключением случая отзыва согласия, мы можем взимать разумную плату,
            если ваш запрос является явно необоснованным, повторяющимся или
            чрезмерным, а также мы можем отказать в выполнении вашего запроса в
            таких обстоятельствах. <br /><br />
            Нам может потребоваться запросить у вас конкретную информацию для
            подтверждения вашей личности и обеспечения вашего права на доступ к
            собственным персональным данным (или осуществления любых других
            ваших прав). Эта мера безопасности используется, чтобы
            гарантировать, что личные данные не будут раскрыты лицу, которое не
            имеет права на их получение. Мы также можем связаться с вами, чтобы
            запросить дополнительную информацию, связанную с вашим запросом,
            чтобы ускорить наш ответ. <br /><br />
            Мы постараемся ответить на все законные обращения в течение одного
            месяца. Если ваш запрос является особенно сложным или вы сделали ряд
            запросов, может потребоваться больше месяца. В этом случае мы
            уведомим вас и будем держать в курсе.
            <h2 class="sub-title-static-text">Жалобы</h2>
            <span>
                Если вы хотите подать жалобу в отношении данной Политики
                конфиденциальности или того, как мы используем ваши Персональные
                данные, свяжитесь с нами, по e-mail:
                <a href="mailto:admin@minexia.ru" class="st-link">admin@minexia.ru</a></span><br />
            Если вы считаете, что обработка персональных данных нарушает
            действующее законодательство о защите данных, вы имеете право в
            любое время подать жалобу в компетентный надзорный орган.
        </div>
    </div>
</template>
<script>
export default {
    created() {
        document.title = "Minexia - политика конфиденциальности";
    },
};
</script>
<style>
.only-text-container {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.01em;
    color: #ffffff;
}

.title-static-text {
    font-size: 44px;
    margin-bottom: 25px;
}

.sub-title-static-text {
    font-size: 24px;
    line-height: 24px;
    margin: 15px 0px 5px 0;
    font-weight: 500;
}

.static-list {
    list-style-type: disc;
    margin: 5px 0 5px 17px;
}

.not-full-line {
    width: fit-content;
}

.mb-5 {
    margin-bottom: 5px;
}

.mt-5 {
    margin-top: 5px;
}

@media only screen and (max-width: 440px) {
    .only-text-container {
        text-align: justify;
    }
}
</style>

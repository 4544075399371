<template>
    <div class="mw-1560 first-cont">
        <div class="title-page">
            <h2>Донат привилегии</h2>
            <p>Расширь свои возможности, получи увеличенное количество приватов, варпов и многое другое...</p>
        </div>
        <div class="donate-grid donate">
            <div class="donate-card f-row">
                <div class="decor-img pos-rel">
                    <img src="@/assets/images/donate-decor.png" alt="">
                    <router-link to="/info/donate/kits" class="b-kits pos-abs">
                        Наборы
                    </router-link>
                </div>
                <div class="donate-list f-col">
                    <p class="donate-name">
                        Ludium
                    </p>
                    <ul>
                        <li>Вход на заполненный сервер</li>
                        <li>/enderchest</li>
                        <li>5 приватов</li>
                        <li>3840 Блоков по площади на приват</li>
                    </ul>
                    <div class="f-row donate-price">
                        <div class="time-grid">
                            <div class="selected">30 дней</div>
                        </div>
                        <div class="price-wrapper f-col">
                            <p>
                                129₽
                            </p>
                            <div>Приобрести</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="donate-card f-row">
                <div class="decor-img pos-rel">
                    <img src="@/assets/images/donate-decor.png" alt="">
                    <router-link to="/info/donate/kits" class="b-kits pos-abs">
                        Наборы
                    </router-link>
                </div>
                <div class="donate-list f-col">
                    <p class="donate-name">
                        Argentum
                    </p>
                    <ul>
                        <li>все привилегии Ludium</li>
                        <li>/repair - починить предмет</li>
                        <li>/heal - исцелить себя</li>
                        <li>/fly - полет</li>
                        <li>7 приватов</li>
                        <li>6400 блоков на приват</li>
                        <li>3 Варпа</li>
                    </ul>
                    <div class="f-row donate-price">
                        <div class="time-grid">
                            <div class="selected">30 дней</div>
                        </div>
                        <div class="price-wrapper f-col">
                            <p>
                                289₽
                            </p>
                            <div>Приобрести</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="donate-card f-row">
                <div class="decor-img pos-rel">
                    <img src="@/assets/images/donate-decor.png" alt="">
                    <router-link to="/info/donate/kits" class="b-kits pos-abs">
                        Наборы
                    </router-link>
                </div>
                <div class="donate-list f-col">
                    <p class="donate-name">
                        Aurum
                    </p>
                    <ul>
                        <li>Все привилегии Argentum</li>
                        <li>/god - бессмертие</li>
                        <li>/firework - запусти фейерверк</li>
                        <li>8 приватов</li>
                        <li>12544 блока на приват</li>
                        <li>10 точек дома</li>
                        <li>5 Варпов</li>
                    </ul>
                    <div class="f-row donate-price">
                        <div class="time-grid">
                            <div class="selected">30 дней</div>
                        </div>
                        <div class="price-wrapper f-col">
                            <p>
                                489₽
                            </p>
                            <div>Приобрести</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="donate-card f-row">
                <div class="decor-img pos-rel">
                    <img src="@/assets/images/donate-decor.png" alt="">
                    <router-link to="/info/donate/kits" class="b-kits pos-abs">
                        Наборы
                    </router-link>
                </div>
                <div class="donate-list f-col">
                    <p class="donate-name">
                        Platinum
                    </p>
                    <ul>
                        <li>все привилегии Aurum</li>
                        <li>/heal nick</li>
                        <li>/feed nick</li>
                        <li>/heal и /feed без задержки</li>
                        <li>/jump</li>
                        <li>10 приватов</li>
                        <li>20736 Блоков на приват</li>
                        <li>7 Варпов</li>
                    </ul>
                    <div class="f-row donate-price">
                        <div class="time-grid">
                            <div class="selected">30 дней</div>
                        </div>
                        <div class="price-wrapper f-col">
                            <p>
                                659₽
                            </p>
                            <div>Приобрести</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="donate-card f-row">
                <div class="decor-img pos-rel">
                    <img src="@/assets/images/donate-decor.png" alt="">
                    <router-link to="/info/donate/kits" class="b-kits pos-abs">
                        Наборы
                    </router-link>
                </div>
                <div class="donate-list f-col">
                    <p class="donate-name">
                        Legendary
                    </p>
                    <ul>
                        <li>Все привилегии Platinum</li>
                        <li>/speed</li>
                        <li>/vanish</li>
                        <li>anti flud</li>
                        <li>доступ к закрытым тестовым серверам</li>
                        <li>15 приватов</li>
                        <li>30864 Блоков на приват</li>
                        <li>10 Варпов</li>
                    </ul>
                    <div class="f-row donate-price">
                        <div class="time-grid">
                            <div class="selected">30 дней</div>
                        </div>
                        <div class="price-wrapper f-col">
                            <p>
                                989₽
                            </p>
                            <div>Приобрести</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/components/DonateView.scss";
</style>
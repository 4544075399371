<template>
    <div class="mw-1560 first-cont">
        <kinesis-container>
            <div class="server-back pos-rel">
                <picture class="pers-img pos-abs">
                    <source type="image/webp" srcset="
                            @/assets/images/servers/doll_astral_greeting.webp
                        " />
                    <source type="image/png" srcset="/assets/images/servers/doll_astral_greeting.png" />
                    <img src="/assets/images/servers/doll_astral_greeting" alt="Изображение" />
                </picture>
                <kinesis-element :strength="10" class="pos-abs io z5">
                    <img src="@/assets/images/servers/io.png" alt="f" class="" />
                </kinesis-element>
                <kinesis-element :strength="20" class="pos-abs l-forest z5">
                    <img src="@/assets/images/servers/l-forest.png" alt="f" class="" />
                </kinesis-element>
                <kinesis-element :strength="40" class="pos-abs p-thaum z5">
                    <img src="@/assets/images/servers/p-thaum.png" alt="f" class="" />
                </kinesis-element>

                <div class="server-title z5 pos-rel">
                    <h3>Mysterium 1.12.2</h3>
                    <p>
                        Завораживающий магический мир с каплей технологий!
                        <br />
                        окунись в мир магии с головой
                    </p>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category">Информация о сервере</p>
                    <div class="f-row g15">
                        <div class="server-main-info f-col ali-c bc-red">
                            <p class="main-info-t">последний вайп</p>
                            <p class="desc-main-info">02.12.2023</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">вайп доп.миров</p>
                            <p class="desc-main-info">каждые 30 дней</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">режим</p>
                            <p class="desc-main-info">PVP</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">основной мир</p>
                            <p class="desc-main-info">10 000 x 10 000</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">ад</p>
                            <p class="desc-main-info">5 000 x 5 000</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">край</p>
                            <p class="desc-main-info">5 000 x 5 000</p>
                        </div>
                    </div>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category" :class="{ fullMods: clientMods }"
                        @click="this.clientMods = !this.clientMods">
                        Модификации сервера <arrow-spoiler />
                    </p>
                    <div class="f-row mods-grid" ref="servermods">
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>thaumcraft 6</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thaumcraft | addon Thaumic Additions</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thaumcraft | addon Thaumic Additions</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thaumcraft | addon Thaumic Periphery</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thaumcraft | addon Thaumic Augmentation</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thaumcraft | addon Crimson Warfare</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thaumcraft | addon Inventory Scanning</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Blood Magic</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Blood Magic | addon <br />
                                Blood Arsenal
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Botania</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Botania | addon Extra Botany</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Astral Sorcery</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Embers</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Embers | addon <br />
                                Soot
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Embers | addon <br />
                                Aether Works
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Embers | addon <br />
                                Aetherium Ashen Armor
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Bewitchment</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Ice and Fire</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>The Betweenlands</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Fish's Undead Rising</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Arcane Archives</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Twilight Forest</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Storage Drawers</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Block Craftery</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Quark</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Champions</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Consecration</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Grimoire of Gaia</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Better Mineshafts</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Better Caves</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Superior Shields</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Unique Enchantments Base</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Unique Enchantments Utils</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Unique Enchantments Battle</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Unique Enchantments</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Living Enchantment</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Specials <br />
                                собственная разработка
                            </p>
                        </div>
                    </div>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category" :class="{ fullMods: serverMods }"
                        @click="this.serverMods = !this.serverMods">
                        Модификации клиента <arrow-spoiler />
                    </p>
                </div>
            </div>
        </kinesis-container>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/serverdesc.scss";
</style>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import ArrowSpoiler from "@/components/icons/ArrowSpoiler.vue";

export default {
    components: {
        ArrowSpoiler,
        KinesisContainer,
        KinesisElement,
    },

    data() {
        return {
            serverMods: false,
            clientMods: false,
        };
    },

    created() {
        document.title = "Mysterium - междуземье ждет";
    },
};
</script>

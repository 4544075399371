import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";

import openAuthhModal from "@/stores/modules/openAuthModal.js";
import openLaunchModal from "@/stores/modules/openLauncherModal.js";
import openCreatePayModal from "@/stores/modules/openCreatePayModal.js";
import userStore from "@/stores/modules/userStore.js";

const persist = new createPersistedState({
    key: "vuex",
    storage: window.localStorage,
    reducer(state) {
        return {
            userStore: state.userStore,
            openLaunchModal: state.openLaunchModal
        };
    }
});

const store = createStore({
    modules: { openLaunchModal, userStore, openAuthhModal, openCreatePayModal },

    plugins: [ persist ]
});

export default store;

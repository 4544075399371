import { createRouter, createWebHistory } from "vue-router";
import IndexUser from "@/views/user/IndexView.vue";
import MonitoringUser from "@/views/user/views/MonitoringsView.vue";
import ServersUser from "@/views/user/views/ServersView.vue";
import DonateUser from "@/views/user/views/DonateView.vue";
import RulesUser from "@/views/user/views/RulesView.vue";
import PolyticsUser from "@/views/user/views/PolyticsView.vue";

import Cabinet from "@/views/user/cabinet/IndexCabinet.vue"

import Registration from "@/views/user/RegistryView.vue";
import RegistryConfirm from "@/views/user/RegistryConfirm.vue";
import RegistryComplete from "@/views/user/RegistryComplete.vue";
import Lostpassword from "@/views/user/RecoveryPasswordView.vue";
import Lostconfirm from "@/views/user/RecoveryConfirm.vue";

import FullNewsPost from "@/views/user/views/FullNewsPost.vue";

import ServerMysterium from "@/views/user/views/servers/ServerMysterium.vue"
import ServerMagic from "@/views/user/views/servers/ServerMagic.vue"
import ServerHitech from "@/views/user/views/servers/ServerHitech.vue"
import ServerTMRPG from "@/views/user/views/servers/ServerTMRPG.vue"

import MainShop from "@/views/user/shop/MainShopView.vue"


import DevTechnical from "@/views/technical/DevView.vue";

const routes = [
	{
		path: "/",
		name: "Index",
		component: IndexUser,
	},
	{
		path: "/auth/register",
		name: "Registration",
		component: Registration,
	},
	{
		path: "/register/confirm/mail",
		name: "RegistryConfirm",
		component: RegistryConfirm,
	},
	{
		path: "/register/complete/:hash",
		name: "RegistryComplete",
		component: RegistryComplete,
	},
	{
		path: "/auth/lostpassword",
		name: "Lostpassword",
		component: Lostpassword,
	},
	{
		path: "/cabinet",
		name: "cabinet",
		component: Cabinet,
	},
	{
		path: "/info/monitorings",
		name: "Monitoring",
		component: MonitoringUser,
	},
	{
		path: "/info/vacancy",
		name: "Vacancy",
		component: DevTechnical,
	},
	{
		path: "/info/donate/kits",
		name: "Kit",
		component: DevTechnical,
	},
	{
		path: "/info/about-project",
		name: "About-project",
		component: DevTechnical,
	},
	{
		path: "/info/polytics",
		name: "Polytics",
		component: PolyticsUser,
	},
	{
		path: "/info/servers",
		name: "Servers",
		component: ServersUser,
	},
	{
		path: "/info/server/Mysterium",
		name: "ServerMysterium",
		component: ServerMysterium,
	},
	{
		path: "/info/server/Magic",
		name: "ServerMagic",
		component: ServerMagic,
	},
	{
		path: "/info/server/HiTech",
		name: "ServerHitech",
		component: ServerHitech,
	},
	{
		path: "/info/server/TechnoMagicRPG",
		name: "ServerTMRPG",
		component: ServerTMRPG,
	},
	{
		path: "/info/donate",
		name: "Donate",
		component: DonateUser,
	},
	{
		path: "/info/rules",
		name: "Rules",
		component: RulesUser,
	},
	{
		path: "/news/:id",
		name: "FullNewsPost",
		component: FullNewsPost,
	},
	{
		path: "/recovery/confirm/:hash",
		name: "Lostconfirm",
		component: Lostconfirm,
	},
	{
		path: "/faq",
		name: "FAQ",
		component: DevTechnical,
	},
	{
		path: "/team",
		name: "team",
		component: DevTechnical,
	},
	{
		path: "/shop/main/",
		name: "MainShop",
		component: MainShop,
	}
];



const router = createRouter({
	routes,
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior() {
		return {
			top: -10,
		}
	},
});

export default router;

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="149" height="115" viewBox="0 0 149 115" fill="none">
        <g filter="url(#filter0_d_203_943)">
            <path
                d="M32.2673 39.8682C32.1912 39.8682 32.1265 39.8458 32.0732 39.8008C32.0276 39.7484 32.0047 39.6847 32.0047 39.6097V32.2698C32.0047 32.1873 32.0276 32.1236 32.0732 32.0787C32.1265 32.0262 32.1912 32 32.2673 32H32.8382C32.9296 32 32.9981 32.0225 33.0437 32.0674C33.0894 32.1124 33.116 32.1499 33.1237 32.1798L35.5442 36.7209L37.9762 32.1798C37.9914 32.1499 38.0181 32.1124 38.0561 32.0674C38.1018 32.0225 38.1703 32 38.2616 32H38.8211C38.9048 32 38.9695 32.0262 39.0152 32.0787C39.0685 32.1236 39.0951 32.1873 39.0951 32.2698V39.6097C39.0951 39.6847 39.0685 39.7484 39.0152 39.8008C38.9695 39.8458 38.9048 39.8682 38.8211 39.8682H38.2616C38.1855 39.8682 38.1208 39.8458 38.0675 39.8008C38.0219 39.7484 37.999 39.6847 37.999 39.6097V34.0795L36.0923 37.7438C36.0618 37.8188 36.0162 37.8787 35.9553 37.9237C35.8944 37.9611 35.8182 37.9799 35.7269 37.9799H35.373C35.274 37.9799 35.1941 37.9611 35.1332 37.9237C35.0799 37.8787 35.0342 37.8188 34.9962 37.7438L33.0894 34.0795V39.6097C33.0894 39.6847 33.0628 39.7484 33.0095 39.8008C32.9638 39.8458 32.9029 39.8682 32.8268 39.8682H32.2673Z"
                fill="#FF4646" />
            <path
                d="M48.1944 39.8682C48.1107 39.8682 48.046 39.8458 48.0003 39.8008C47.9547 39.7484 47.9318 39.6847 47.9318 39.6097V32.2585C47.9318 32.1836 47.9547 32.1236 48.0003 32.0787C48.046 32.0262 48.1107 32 48.1944 32H48.7996C48.8757 32 48.9366 32.0262 48.9823 32.0787C49.0356 32.1236 49.0622 32.1836 49.0622 32.2585V39.6097C49.0622 39.6847 49.0356 39.7484 48.9823 39.8008C48.9366 39.8458 48.8757 39.8682 48.7996 39.8682H48.1944Z"
                fill="#FF4646" />
            <path
                d="M58.1785 39.8682C58.0948 39.8682 58.0301 39.8458 57.9844 39.8008C57.9388 39.7484 57.9159 39.6847 57.9159 39.6097V32.2698C57.9159 32.1873 57.9388 32.1236 57.9844 32.0787C58.0301 32.0262 58.0948 32 58.1785 32H58.7038C58.7951 32 58.8636 32.0225 58.9093 32.0674C58.9549 32.1049 58.9816 32.1349 58.9892 32.1574L62.7799 37.8899V32.2698C62.7799 32.1873 62.8027 32.1236 62.8484 32.0787C62.8941 32.0262 62.9588 32 63.0425 32H63.5905C63.6743 32 63.739 32.0262 63.7846 32.0787C63.8379 32.1236 63.8646 32.1873 63.8646 32.2698V39.5985C63.8646 39.6734 63.8379 39.7371 63.7846 39.7896C63.739 39.842 63.6781 39.8682 63.602 39.8682H63.0539C62.9702 39.8682 62.9055 39.8458 62.8598 39.8008C62.8218 39.7558 62.7951 39.7259 62.7799 39.7109L59.0006 34.0345V39.6097C59.0006 39.6847 58.974 39.7484 58.9207 39.8008C58.875 39.8458 58.8103 39.8682 58.7266 39.8682H58.1785Z"
                fill="#FF4646" />
            <path
                d="M72.9683 39.8682C72.8846 39.8682 72.8199 39.8458 72.7742 39.8008C72.7286 39.7484 72.7057 39.6847 72.7057 39.6097V32.2698C72.7057 32.1873 72.7286 32.1236 72.7742 32.0787C72.8199 32.0262 72.8846 32 72.9683 32H77.6839C77.7676 32 77.8323 32.0262 77.878 32.0787C77.9236 32.1236 77.9465 32.1873 77.9465 32.2698V32.6969C77.9465 32.7793 77.9236 32.843 77.878 32.888C77.8323 32.9329 77.7676 32.9554 77.6839 32.9554H73.8018V35.4171H77.4327C77.5164 35.4171 77.5811 35.4433 77.6268 35.4958C77.6725 35.5407 77.6953 35.6044 77.6953 35.6868V36.1027C77.6953 36.1777 77.6725 36.2414 77.6268 36.2938C77.5811 36.3388 77.5164 36.3613 77.4327 36.3613H73.8018V38.9128H77.7752C77.8589 38.9128 77.9236 38.9353 77.9693 38.9803C78.015 39.0252 78.0378 39.0889 78.0378 39.1713V39.6097C78.0378 39.6847 78.015 39.7484 77.9693 39.8008C77.9236 39.8458 77.8589 39.8682 77.7752 39.8682H72.9683Z"
                fill="#FF4646" />
            <path
                d="M86.0125 39.8682C85.9516 39.8682 85.8945 39.8458 85.8412 39.8008C85.7955 39.7558 85.7727 39.7034 85.7727 39.6434C85.7727 39.6135 85.7765 39.5872 85.7841 39.5648C85.7994 39.5348 85.8108 39.5123 85.8184 39.4973L88.433 35.8667L85.944 32.3709C85.9211 32.326 85.9097 32.2773 85.9097 32.2248C85.9097 32.1649 85.9326 32.1124 85.9782 32.0674C86.0239 32.0225 86.0772 32 86.1381 32H86.8231C86.8993 32 86.9601 32.0187 87.0058 32.0562C87.0515 32.0862 87.0934 32.1311 87.1314 32.1911L89.1409 35.0012L91.139 32.1911C91.1695 32.1311 91.2075 32.0862 91.2532 32.0562C91.3065 32.0187 91.3674 32 91.4359 32H92.0867C92.1476 32 92.2009 32.0225 92.2466 32.0674C92.2922 32.1124 92.3151 32.1649 92.3151 32.2248C92.3151 32.2773 92.2998 32.326 92.2694 32.3709L89.8146 35.8779L92.4178 39.4973C92.4331 39.5123 92.4445 39.5348 92.4521 39.5648C92.4597 39.5872 92.4635 39.6135 92.4635 39.6434C92.4635 39.7034 92.4407 39.7558 92.395 39.8008C92.3493 39.8458 92.2922 39.8682 92.2237 39.8682H91.5272C91.4587 39.8682 91.3978 39.8495 91.3446 39.812C91.2989 39.7746 91.257 39.7334 91.219 39.6884L89.0953 36.7659L86.983 39.6884C86.9449 39.7334 86.9031 39.7746 86.8574 39.812C86.8117 39.8495 86.7508 39.8682 86.6747 39.8682H86.0125Z"
                fill="#FF4646" />
            <path
                d="M100.842 39.8682C100.758 39.8682 100.694 39.8458 100.648 39.8008C100.602 39.7484 100.579 39.6847 100.579 39.6097V32.2585C100.579 32.1836 100.602 32.1236 100.648 32.0787C100.694 32.0262 100.758 32 100.842 32H101.447C101.523 32 101.584 32.0262 101.63 32.0787C101.683 32.1236 101.71 32.1836 101.71 32.2585V39.6097C101.71 39.6847 101.683 39.7484 101.63 39.8008C101.584 39.8458 101.523 39.8682 101.447 39.8682H100.842Z"
                fill="#FF4646" />
            <path
                d="M110.027 39.8682C109.966 39.8682 109.913 39.8458 109.867 39.8008C109.821 39.7558 109.798 39.7034 109.798 39.6434C109.798 39.606 109.802 39.5648 109.81 39.5198L112.641 32.2473C112.672 32.1724 112.714 32.1124 112.767 32.0674C112.82 32.0225 112.9 32 113.007 32H113.783C113.882 32 113.958 32.0225 114.012 32.0674C114.073 32.1124 114.118 32.1724 114.149 32.2473L116.969 39.5198C116.984 39.5648 116.992 39.606 116.992 39.6434C116.992 39.7034 116.969 39.7558 116.923 39.8008C116.877 39.8458 116.824 39.8682 116.763 39.8682H116.181C116.09 39.8682 116.021 39.8458 115.975 39.8008C115.937 39.7558 115.911 39.7146 115.896 39.6772L115.268 38.081H111.523L110.895 39.6772C110.887 39.7146 110.86 39.7558 110.815 39.8008C110.769 39.8458 110.7 39.8682 110.609 39.8682H110.027ZM111.854 37.1144H114.936L113.395 33.124L111.854 37.1144Z"
                fill="#FF4646" />
            <path
                d="M64.7348 63.3552L52.6462 73.8655L40.5028 63.3552V75.5434L32 82.8338L32.0001 44.864L52.6462 62.748L73.242 44.8641V77.331H64.7348V63.3552Z"
                fill="#FF4646" />
            <path d="M108.552 66.1602V44.864H117V83L84.2606 55.4273V77.3408H75.7581V44.8643L84.2607 44.864L108.552 66.1602Z"
                fill="#FF4646" />
    </g>
    <defs>
        <filter id="filter0_d_203_943" x="0" y="0" width="149" height="115" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="16" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.275 0 0 0 0 0.275 0 0 0 0.3 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_203_943" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_203_943" result="shape" />
        </filter>
    </defs>
</svg></template>

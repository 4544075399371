<template>
    <div></div>
</template>
<script>
import axios from "axios";

export default {
    setup() { },
    data() {
        return {
            shopData: 1,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initShop();
        });
    },
    methods: {
        async initShop() {
            try {
                const result = (
                    await axios.post(
                        `https://api.minexia.ru/v2/shop/getshop`,
                        this.shopData,
                        {
                            headers: {
                                authorization: `Bearer ${this.$store.getters.whatUserToken}`,
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                ).data;
                console.log(result);
            } catch (e) {
                if (e.response.status === 500) {
                    this.toast.error(
                        "Что-то пошло не так, попробуйте позднее! (>ᆺ>)",
                        {
                            position: "bottom-right",
                        }
                    );
                }
            }
        },
    },
};
</script>

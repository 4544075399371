export default {
    state: {
       openCreatePayModal: false,
       openConvertModal: false
    },

    mutations: {
        openModalCreatePay (state) {
           state.openCreatePayModal = true;
       },
        closeModalCreatePay (state) {
           state.openCreatePayModal = false;
       },
       openConvertModal (state) {
            state.openConvertModal = true;
        },
        closeConvertModal (state) {
            state.openConvertModal = false;
        }
    },
    
    getters: {
        openCreatePayModal (state) {
            return state.openCreatePayModal
        },
        openConvertModal (state) {
            return state.openConvertModal
        },
    }
}

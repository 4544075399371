<template>
  <svg
    width="140"
    height="112"
    viewBox="0 0 140 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.0101 111.891L47.6674 108.019C46.2675 107.625 45.48 106.159 45.8738 104.759L75.7308 1.89514C76.1245 0.49503 77.59 -0.292529 78.9899 0.101251L92.3326 3.97342C93.7325 4.3672 94.52 5.83293 94.1262 7.23304L64.2692 110.097C63.8536 111.497 62.41 112.307 61.0101 111.891ZM36.0745 87.3453L45.5894 77.1945C46.5956 76.1226 46.53 74.4162 45.4144 73.4318L25.5972 55.9961L45.4144 38.5604C46.53 37.5759 46.6174 35.8696 45.5894 34.7976L36.0745 24.6468C35.0902 23.5968 33.4279 23.5311 32.3561 24.5375L0.836653 54.0709C-0.278884 55.0991 -0.278884 56.8711 0.836653 57.8994L32.3561 87.4547C33.4279 88.461 35.0902 88.4173 36.0745 87.3453ZM107.644 87.4766L139.163 57.9212C140.279 56.893 140.279 55.121 139.163 54.0928L107.644 24.5156C106.594 23.5311 104.932 23.5749 103.925 24.625L94.4106 34.7757C93.4044 35.8477 93.47 37.5541 94.5856 38.5385L114.403 55.9961L94.5856 73.4318C93.47 74.4162 93.3825 76.1226 94.4106 77.1945L103.925 87.3453C104.91 88.4173 106.572 88.461 107.644 87.4766Z"
      fill="#FF4646"
    />
  </svg>
</template>
<template>
    <div class="mw-1560 mx-auto first-cont">
        <h3 class="start-r-title" @click="recaptcha()">РЕГИСТРАЦИЯ</h3>
        <p class="desc-r-title">
            Привет, у нас есть вкусное кофе и уютная атмосфера
        </p>
        <div class="form-block f-col gap15">
            <input v-model="form.username" pattern="[0-9A-Za-z_]{3,16}" maxlength="16" type="text" class="st-input"
                placeholder="Логин от 3 до 16 символов" />
            <input v-model="form.email" type="email" class="st-input" placeholder="example@gmail.com" />
            <input v-model="form.password" type="password" name="password" maxlength="32" autocomplete="off"
                class="st-input" placeholder="Ваш пароль" />
            <vue-recaptcha sitekey="6Lf6jd8fAAAAAG2HcrYdJDHjQ9JW5PEyL0-3nhrV"></vue-recaptcha>
            <button @click="submitReg" class="stButton w100">
                Зарегистрироваться
            </button>
            <div class="reg-desc">
                Регистрируясь на проекте, вы соглашаетесь с
                <router-link to="/info/rules">правилами проекта</router-link> и
                <router-link to="/info/polytics">политикой конфиденциальности</router-link>
            </div>
        </div>


        <VueReCaptcha></VueReCaptcha>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/components/registration.scss";
</style>
<script>
import { useToast } from "vue-toastification";
import axios from "axios";
import { VueReCaptcha } from "vue-recaptcha-v3";

export default {
    components: { VueReCaptcha },
    data() {
        return {
            form: {
                username: "",
                password: "",
                email: "",
                captcha: "",
            },
        };
    },
    setup() {
        return { toast: useToast() };
    },
    methods: {
        async submitReg() {
            await this.$recaptchaLoaded();
            this.$data.form.captcha = await this.$recaptcha("register");
            try {
                const result = (
                    await axios.post(
                        "https://api.minexia.ru/v2/register",
                        this.form
                    )
                ).data;
                this.$store.commit("userSetInfo", result);
                this.$router.push("/register/confirm/mail");
            } catch (e) {
                if (e.response.status === 400) {
                    console.log(e);
                }
            }
        },
    },
};
</script>

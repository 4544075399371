export default {
    state: {
        userStore: {},
        token: "",
        balance: "",
        vbalance: "0"
    },

    getters: {
        whatUserName (state) {
            return state.userStore.username
        },
        whatUserEmail (state) {
            return state.userStore.email
        },
        whatUserToken (state) {
            return state.token
        },
        whatUserBalance (state) {
            return state.balance
        },
        whatUserVBalance (state) {
            return state.vbalance
        },
        whatUserRegistration (state) {
            return state.userStore.register_date
        },
        whatUserID (state) {
            return state.userStore.id
        }
    },

    mutations: {
        userSetInfo (state, info) {
            state.userStore = info;
        },
        userLogout(state) {
            state.userStore = {}
        },
        userSetToken (state, info) {
            state.token = info;
        }, 
        userSetBalance (state, info) {
            state.balance = info;
        },
        userSetVBalance (state, info) {
            state.vbalance = info;
        }
    }
};

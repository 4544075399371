<template>
    <header-nav />
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
    <footer-nav class="z10" />

    <auth-modal v-if="this.$store.getters.openAuthModal" />
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import FooterNav from "@/components/FooterNav.vue";
import AuthModal from "@/components/AuthModal.vue";

export default {
    components: { HeaderNav, FooterNav, AuthModal },
};
</script>
<style lang="scss">
@import "@/assets/scss/classes.scss";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/reseter.css";

.grecaptcha-badge {
    display: none !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

body {
    background-color: #101010;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#app {
    min-height: 100vh;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
}

.liteEl {
    bottom: 10%;
    top: unset;
}
</style>

<template>
    <svg
        width="146"
        height="146"
        viewBox="0 0 146 146"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M36.9363 62.3886H30.1491C20.646 62.3886 15.8945 62.3886 12.2648 64.238C9.07202 65.8648 6.47622 68.4606 4.84942 71.6534C3 75.2831 3 80.0346 3 89.5377V115.838C3 125.341 3 130.093 4.84942 133.723C6.47622 136.915 9.07202 139.511 12.2648 141.138C15.8945 142.987 20.646 142.987 30.1491 142.987H36.9363M36.9363 62.3886H37.3022C40.9725 62.3886 42.8077 62.3886 44.3498 61.7587C45.7109 61.2028 46.9042 60.3027 47.8127 59.1467C48.8419 57.8369 49.3461 56.0724 50.3544 52.5433L62.7543 9.14372C63.7933 5.50718 67.1171 3 70.8992 3C80.2558 3 87.8408 10.585 87.8408 19.9417V40.33C87.8408 45.0815 87.8408 47.4573 88.7655 49.2721C89.5789 50.8685 90.8768 52.1664 92.4732 52.9798C94.2881 53.9045 96.6638 53.9045 101.415 53.9045H112.699C124.918 53.9045 130.991 53.9045 135.097 56.449C138.695 58.6779 141.408 62.1706 142.563 66.2417C143.882 70.8892 142.102 76.7634 138.746 88.512L128.819 123.297C126.802 130.355 125.787 133.884 123.729 136.504C121.912 138.816 119.579 140.616 116.856 141.728C113.772 142.987 110.114 142.987 102.773 142.987H36.9363M36.9363 62.3886V142.987"
            stroke="#FF4646"
            stroke-width="5"
            stroke-linejoin="round"
        />
    </svg>
</template>

<template>
  <div class="mw-1560 mx-auto first-cont f-col technical-notification jc-c">
    <dev-icon />
    <h4>Данный раздел в разработке</h4>
    <p>Совсем скоро, или уже завтра, данный раздел выйдет в релиз.</p>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/registration.scss";

.technical-notification {
  height: calc(100vh - (86px + 220px + 60px));

  h4 {
    font-size: 28px;
    color: $stWText;
    margin: 20px 0 5px;
  }

  p {
    color: $stWText;
    font-size: 18px;
    max-width: 600px;
    text-align: center;
  }
}
</style>

<script>
import DevIcon from "@/assets/icons/DevIcon.vue";

export default {
  components: { DevIcon },
};
</script>

<template>
    <div class="mw-1560 mx-auto first-cont">
        <h3 class="start-r-title">Восстановление пароля</h3>
        <p class="desc-r-title">
            Забыли пароль? Восстановите его без особых проблем и усилий
        </p>
        <div class="f-col gap15">
            <input v-model="form.email" type="text" class="st-input mb-15" placeholder="Ваш e-mail" />
        </div>
        <button @click="submitRecovery" class="stButton w100">Отправить</button>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/components/registration.scss";
</style>
<script>
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
    data() {
        return {
            form: {
                email: "",
            },
        };
    },
    setup() {
        return { toast: useToast() };
    },
    methods: {
        async submitRecovery() {
            try {
                await axios.post(
                    "https://api.minexia.ru/v2/recovery",
                    this.form
                );
                this.toast.success(`Заявка отправлена на вашу почту`, {
                    position: "bottom-right",
                });
            } catch (e) {
                if (e.response.status === 400) {
                    console.log(e);
                }
            }
        },
    },
    created() {
        document.title = "Minexia - Восстановлени пароля";
    },
};
</script>

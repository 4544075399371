<template>
    <div class="mw-1560 first-cont">
        <kinesis-container>
            <div class="server-back pos-rel">
                <picture class="pers-img pos-abs">
                    <source type="image/webp" srcset="
                            @/assets/images/servers/doll_thaum_greeting.webp
                        " />
                    <source type="image/png" srcset="/assets/images/servers/doll_thaum_greeting.png" />
                    <img src="/assets/images/servers/doll_thaum_greeting" alt="Изображение" />
                </picture>
                <kinesis-element :strength="10" class="pos-abs io z5">
                    <img src="@/assets/images/servers/magic1.png" alt="f" class="" />
                </kinesis-element>
                <kinesis-element :strength="20" class="pos-abs l-forest z5">
                    <img src="@/assets/images/servers/magic2.png" alt="f" class="" />
                </kinesis-element>
                <kinesis-element :strength="40" class="pos-abs p-thaum z5">
                    <img src="@/assets/images/servers/magic3.png" alt="f" class="" />
                </kinesis-element>

                <div class="server-title z5 pos-rel">
                    <h3>Magic 1.7.10</h3>
                    <p>Самый древний магический мир</p>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category">Информация о сервере</p>
                    <div class="f-row g15">
                        <div class="server-main-info f-col ali-c bc-red">
                            <p class="main-info-t">последний вайп</p>
                            <p class="desc-main-info">02.12.2023</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">вайп доп.миров</p>
                            <p class="desc-main-info">каждые 30 дней</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">режим</p>
                            <p class="desc-main-info">PVP</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">основной мир</p>
                            <p class="desc-main-info">10 000 x 10 000</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">ад</p>
                            <p class="desc-main-info">5 000 x 5 000</p>
                        </div>
                        <div class="server-main-info f-col ali-c">
                            <p class="main-info-t">край</p>
                            <p class="desc-main-info">5 000 x 5 000</p>
                        </div>
                    </div>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category" :class="{ fullMods: clientMods }"
                        @click="this.clientMods = !this.clientMods">
                        Модификации сервера <arrow-spoiler />
                    </p>
                    <div class="f-row mods-grid" ref="servermods">
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Botania</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Botania | addon <br />
                                Alfheim
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Botania | addon <br />
                                Alfheim-modular
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Thaumcraft</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Automagy
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Automagy
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Forbidden Magic
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Gadomancy
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Tainted Magic
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Thaumic Bases
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Thaumic Horizons
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Thaumic tinkerer
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>
                                Thaumcraft | addon <br />
                                Thaumic Witching Gadgets
                            </p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>The Twilight Forest</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Tinker's Construct</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Travellers Gear</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Vending</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Witchery</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Iron Chest</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Hardcore Ender Expansion</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Chisel</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>CustomNpcs</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Baubles</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>BiblioCraft</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Carpenters Block</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Better Questing</p>
                        </div>
                    </div>
                </div>
                <div class="server-info server-f-m f-col z10 pos-rel">
                    <p class="server-category" :class="{ fullMods: serverMods }"
                        @click="this.serverMods = !this.serverMods">
                        Модификации клиента <arrow-spoiler />
                    </p>
                    <div class="f-row mods-grid">
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>OptiFine</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Nei</p>
                        </div>
                        <div class="mod-wrapper ali-c jc-c f-col">
                            <p>Journey Map</p>
                        </div>
                    </div>
                </div>
            </div>
        </kinesis-container>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/serverdesc.scss";
</style>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import ArrowSpoiler from "@/components/icons/ArrowSpoiler.vue";

export default {
    components: {
        ArrowSpoiler,
        KinesisContainer,
        KinesisElement,
    },

    data() {
        return {
            serverMods: false,
            clientMods: false,
        };
    },

    created() {
        document.title = "Magic - древний магический мир";
    },
};
</script>
